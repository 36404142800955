import React, { useEffect, useState } from "react";
import ReactQuill from 'react-quill';
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter
} from "reactstrap";

function Associates() {
  const [departments, setDepartments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [selectedDepartmentId, setSelectedDepartmentId] = useState(null);
  const [addAssociate, setAddAssociate] = useState({
    image: "",
    headtitle: "",
    title: "",
    paragraph: "",
    content: "",
    order: "",
  });
  
  const [updateDepartment, setUpdateDepartment] = useState({
    image: "",
    headtitle: "",
    title: "",
    paragraph: "",
    content: "",
    order: "", // Add order here
  });
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://back.zubilaw.com/AssociatesRouters/list");
      if (response.data.success) {
        setDepartments(response.data.data);
      } else {
        console.error("Failed to fetch departments:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleAddDepartment = async () => {
    const headtitle = addAssociate.headtitle;
    const title = addAssociate.title;
    const paragraph = addAssociate.paragraph;
    const content = addAssociate.content;
    const mainImageInput = document.getElementById('newDepartmentMainImage');
    const order = addAssociate.order;
    
    let errors = {};
    if (!headtitle) errors.headtitle = "Head title is required";
    if (!title) errors.title = "Title is required";
    if (!paragraph) errors.paragraph = "Paragraph is required";
    if (!content) errors.content = "Content is required";
    if (!order) errors.order = "Order is required";
    if (mainImageInput.files[0] && !isValidImageExtension(mainImageInput.files[0].name)) {
      errors.image = "Invalid main image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.";
    }
    
    if (Object.keys(errors).length > 0) {
      setFieldErrors(errors);
      return;
    }
    
    const formData = new FormData();
    formData.append('headtitle', headtitle);
    formData.append('title', title);
    formData.append('paragraph', paragraph);
    formData.append('content', content);
    formData.append('order', order);
    if (mainImageInput.files[0]) {
      formData.append('image', mainImageInput.files[0]);
    }
    
    try {
      const response = await axios.post('https://back.zubilaw.com/AssociatesRouters/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('Associate added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData();
        setAddAssociate({
          image: "",
          headtitle: "",
          title: "",
          paragraph: "",
          content: "",
          order: "",
        });
        mainImageInput.value = '';
        setFieldErrors({});
      }
    } catch (error) {
      console.error('Error adding associate:', error);
      setAddError('Failed to add associate. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  
  
  
  
  const handleUpdate = (department) => {
    if (department.id) {
      setSelectedDepartmentId(department.id);
      setUpdateDepartment({
        headtitle: department.headtitle,
        title: department.title,
        paragraph: department.paragraph,
        content: department.content,
        department: department.department,
        image: department.image,
        order: department.order, // Set order
      });
      setShowUpdateImage(true);
    }
  };

  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (selectedDepartmentId) {
      formData.append('headtitle', updateDepartment.headtitle);
      formData.append('title', updateDepartment.title);
      formData.append('paragraph', updateDepartment.paragraph);
      formData.append('content', updateDepartment.content);
      formData.append('department', updateDepartment.department);
      formData.append('order', updateDepartment.order); // Append order
      if (updateDepartment.image instanceof File) {
        if (!isValidImageExtension(updateDepartment.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateDepartment.image);
      }

      try {
        const response = await axios.put(`https://back.zubilaw.com/AssociatesRouters/update/${selectedDepartmentId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Department updated successfully:", response.data);
        fetchData();
        setUpdateSuccessMessage("Department updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setShowUpdateImage(false);
      } catch (error) {
        console.error("Error updating department:", error);
        setUpdateError("Error updating department");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://back.zubilaw.com/AssociatesRouters/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/AssociatesRouters/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("Department image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
                
            } catch (error) {
                console.error("Error updating department image:", error);
                setUpdateError("Error updating department image");
                setTimeout(() => setUpdateError(""), 5000);

            }
        } else {
            setUpdateError("Invalid image file extension for department image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};

const handleDeleteDepartment = (id) => {
    setSelectedDepartmentId(id);
    setShowDeletePopup(true);
  };
  
const performDelete = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/AssociatesRouters/delete/${selectedDepartmentId}`);
      if (response.status === 200) {
        const updatedDepartments = departments.filter(item => item.id !== selectedDepartmentId);
        setDepartments(updatedDepartments);
        setShowDeletePopup(false);
      } else {
        console.error('Failed to delete department item');
      }
    } catch (error) {
      console.error('Error deleting department item:', error);
    }
};


const modules = {
  toolbar: [
    [{ 'color': [] }, { 'background': [] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'align': [] }],
    ['link', 'image', 'video'],
    ['clean']                                         
  ],
};

const formats = [
  'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
  'list', 'bullet', 'align', 'link', 'image', 'video'
];


return (
  <div className="content">
    <Row>
      <Col md="12">
        <Card>
          <CardHeader>
            <CardTitle tag="h4">Associates</CardTitle>
          </CardHeader>
          <CardBody>
          <Form>
  <Row>
    <Col md="7">
      <FormGroup>
        <label>Name:</label>
        <Input
          type="text"
          name="headtitle"
          id="newDepartmentHeadtitle"
          placeholder="Enter Head Title"
          value={addAssociate.headtitle}
          onChange={(e) => setAddAssociate({ ...addAssociate, headtitle: e.target.value })}
        />
        {fieldErrors.headtitle && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.headtitle}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Position:</label>
        <Input
          type="text"
          name="title"
          id="newDepartmentTitle"
          placeholder="Enter Title"
          value={addAssociate.title}
          onChange={(e) => setAddAssociate({ ...addAssociate, title: e.target.value })}
        />
        {fieldErrors.title && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.title}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Order:</label>
        <Input
          type="number"
          name="order"
          id="newDepartmentOrder"
          placeholder="Enter Order"
          value={addAssociate.order}
          onChange={(e) => setAddAssociate({ ...addAssociate, order: e.target.value })}
        />
        {fieldErrors.order && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.order}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label htmlFor="newServiceparagraph" className="textarea-label">Description:</label>
        <div style={{ height: '300px', overflowY: 'auto' }}>
          <ReactQuill
            value={addAssociate.paragraph}
            onChange={(html) => setAddAssociate({ ...addAssociate, paragraph: html })}
            modules={modules}
            formats={formats}
            style={{ height: '80%', color: 'black' }}
          />
        </div>
        {fieldErrors.paragraph && <div style={{ color: 'red', marginTop: '5px' }}>{fieldErrors.paragraph}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Content:</label>
        <div style={{ height: '300px', overflowY: 'auto' }}>
          <ReactQuill
            value={addAssociate.content}
            onChange={(html) => setAddAssociate({ ...addAssociate, content: html })}
            modules={modules}
            formats={formats}
            style={{ height: '80%', color: 'black' }}
          />
        </div>
        {fieldErrors.content && <div style={{ color: 'red', marginTop: '5px' }}>{fieldErrors.content}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Main Image:</label>
        <Input type="file" name="image" id="newDepartmentMainImage" />
        {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" onClick={handleAddDepartment}>
          Add Associates
        </Button>
        {addError && <div style={{ color: "red", marginTop: "10px" }}>{addError}</div>}
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: "50%", margin: "10px auto", textAlign: "center" }}>
            {addSuccessMessage}
          </div>
        )}
      </div>
    </Col>
  </Row>
</Form>

            <Table>
              <thead className="text-primary">
                <tr>
                  <th>Image</th>
                  <th>Name</th>
                  <th>Position</th>
                  <th>Paragraph</th>
                  <th>Content</th>
                  <th>Order</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {departments.map((department, index) => (
                  <tr key={index}>
                    <td>
                      <img src={`https://back.zubilaw.com/${department.image}`} alt="Icon" style={{ width: '200px' }} />
                    </td>
                    <td>{department.headtitle}</td>
                    <td>{department.title}</td>
                    <td dangerouslySetInnerHTML={{ __html: department.paragraph}}></td>
                    <td dangerouslySetInnerHTML={{ __html: department.content}}></td>
                    <td>{department.order}</td>
                    <td>
                      <Button color="success" onClick={() => handleUpdate(department)}>Update</Button>
                    </td>
                    <td>
                      <Button color="danger" onClick={() => handleDeleteDepartment(department.id)}>Delete</Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {showUpdateImage && (
              <div className="content">
                <Form onSubmit={handleEventUpdate}>
                  <Row>
                    <Col md="7">
                      <FormGroup>
                        <label>Head Title:</label>
                        <Input
                          type="text"
                          name="title"
                          value={updateDepartment.headtitle}
                          onChange={(e) => setUpdateDepartment({ ...updateDepartment, headtitle: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="7">
                      <FormGroup>
                        <label>Title:</label>
                        <Input
                          type="text"
                          name="paragraph"
                          value={updateDepartment.title}
                          id="edit-navbar-name"
                          onChange={(e) => setUpdateDepartment({ ...updateDepartment, title: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="7">
                      <FormGroup>
                        <label>Order:</label>
                        <Input
                          type="number"
                          name="order"
                          value={updateDepartment.order}
                          onChange={(e) => setUpdateDepartment({ ...updateDepartment, order: e.target.value })}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="7">
                      <FormGroup>
                        <label>Paragraph:</label>
                        <div
                          style={{
                            height: '300px', // Adjust the height as needed
                            overflowY: 'auto', // Add a vertical scrollbar when content overflows
                          }}
                        >
                          <ReactQuill
                            value={updateDepartment.paragraph} // Initial value for ReactQuill
                            onChange={(html) => setUpdateDepartment({ ...updateDepartment, paragraph: html })}
                            modules={modules} 
  formats={formats}
  style={{ height: '80%', color: 'black' }}
/>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col md="7">
  <FormGroup>
    <label>Content:</label>
    <div
      style={{
        height: '300px',
        overflowY: 'auto',
      }}
    >
      <ReactQuill
        value={updateDepartment.content}
        onChange={(html) => setUpdateDepartment({ ...updateDepartment, content: html })}
        modules={modules}
        formats={formats}
        style={{ height: '80%', color: 'black' }}
      />
    </div>
  </FormGroup>
</Col>

                    <Col md="7">
                      <FormGroup>
                        {updateDepartment.image && (
                          <div>
                            <label>Current Image:</label>
                            <div>
                              <img src={`https://back.zubilaw.com/AssociatesRouters/${updateDepartment.image}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
                            </div>
                          </div>
                        )}
                        <label className="mt-3">Upload New Image:</label>
                        <Input
                          type="file"
                          name="image"
                          onChange={(e) => setUpdateDepartment({ ...updateDepartment, image: e.target.files[0] })}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <div className="update ml-auto mr-auto">
                        <Button className="btn-round" color="primary" type="submit">
                          Update Associates
                        </Button>{" "}
                        <Button
                          className="btn-round"
                          color="secondary"
                          onClick={() => {
                            setShowUpdateImage(false);
                            setSelectedDepartmentId(null);
                          }}
                        >
                          Cancel
                        </Button>
                        {updateErrorMessage && (
                          <div style={{ color: 'red', marginTop: '10px' }}>
                            {updateErrorMessage}
                          </div>
                        )}
                        {updateError && (
                          <div style={{ color: 'red', marginTop: '10px' }}>
                            {updateError}
                          </div>
                        )}
                        {updateSuccessMessage && (
                          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                            {updateSuccessMessage}
                          </div>
                        )}
                        {UpdateRequired && (
                          <div style={{ color: 'red', marginTop: '10px' }}>
                            {UpdateRequired}
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                </Form>
              </div>
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>

    <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
      <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
      <ModalBody>
        Are you sure you want to delete this department item?
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={performDelete}>Delete</Button>{' '}
        <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
      </ModalFooter>
    </Modal>
  </div>
);
}

export default Associates;
