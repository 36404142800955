// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);
  const [NewsSection, setNewsSection] = useState([]);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
    order: "" // Add this line
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [selectedImageId, setselectedImageId] = useState(null);
  const [updateImage, setupdateImage] = useState({
    title: "",
    description: "",
    order: "" // Add this line
  });
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  






  const fetchData = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/whattheysay/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);






  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim()) {
        setUpdateErrorMessage("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://back.zubilaw.com/whattheysay/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("What They Say updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: ""});
          setSelectedTermId(null);
          setSuccessMessage("What They Say updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating What They Say:", error);
        setUpdateErrorMessage("Failed to update What They Say. Please try again.");
      }
    };










  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/textsay/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else 
        console.error("Failed to fetch What They Say:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);
  



  const handleAddService = async () => {
    const title = document.getElementById('newServiceTitle').value;
    const description = document.getElementById('newServicedescription').value;
    const order = document.getElementById('newServiceOrder').value; // Add this line
  
    if (!title.trim() || !description.trim() || !order.trim()) { // Include order in validation
      setAddError('Title, description, and order are required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    try {
      const response = await axios.post('https://back.zubilaw.com/textsay/add', { title, description, order }); // Add order in request payload
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('What They Say added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData1(); // Reload your data here
      }
    } catch (error) {
      console.error('Error adding What They Say:', error);
      setAddError('Failed to add What They Say. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  
  


  

  

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        title: event.title,
        description: event.description,
      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

 
  
  


  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
  
    if (!updateImage.title.trim() || !updateImage.description.trim() || !updateImage.order.trim()) { // Include order in validation
      setUpdateError("Title, description, and order are required.");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }
  
    try {
      const payload = {
        title: updateImage.title,
        description: updateImage.description,
        order: updateImage.order // Add this line
      };
  
      const response = await axios.put(`https://back.zubilaw.com/textsay/update/${selectedImageId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
  
      console.log("What They Say updated successfully:", response.data);
      await fetchData1(); // Ensure this waits for the data to be fetched
      setUpdateSuccessMessage("What They Say updated successfully");
      setTimeout(() => setUpdateSuccessMessage(""), 5000);
      setshowUpdateImage(false);
      setselectedImageId(null);
      setupdateImage({ title: "", description: "", order: "" }); // Reset form
    } catch (error) {
      console.error("Error updating What They Say:", error);
      setUpdateError("Error updating What They Say: " + (error.response?.data?.errors?.message || "Please try again."));
      setTimeout(() => setUpdateError(""), 5000);
    }
  };
  
  


const handleDeleteImage = (id) => {
    setselectedImageId(id);
    setShowDeleteNavbarAboutPopup(true);
  };
  
  const performDeleteNavbarAbout = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/textsay/delete/${selectedImageId}`);
      if (response.status === 200) {
        const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
        setNewsSection(updatedNavbarAboutItems);
        setShowDeleteNavbarAboutPopup(false);
      } else {
        console.error('Failed to delete What They Say');
      }
    } catch (error) {
      console.error('Error deleting What They Say:', error);
    }
  };







  return (

<div className="content">




      




  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>

        <Form>
  <Row>
    <Col md="5">
      <FormGroup>
        <label>Title:</label>
        <Input type="text" name="title" id="newServiceTitle" />
      </FormGroup>
    </Col>
    <Col md="5">
      <FormGroup>
        <label>Description:</label>
        <Input type="text" name="description" id="newServicedescription" />
      </FormGroup>
    </Col>
    <Col md="5">  {/* Add this block for Order */}
      <FormGroup>
        <label>Order:</label>
        <Input type="number" name="order" id="newServiceOrder" />
      </FormGroup>
    </Col>
    <Col md="12">
      <Button className="btn-round" color="primary" onClick={handleAddService}>
        Add What They Say
      </Button>
      {addSuccessMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {addSuccessMessage}
        </div>
      )}
      {addError && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {addError}
        </div>
      )}
      {ImageError && (
        <div style={{ color: 'red' }}>
          {ImageError}
        </div>
      )}
    </Col>
  </Row>
</Form>



          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Title</th>
                <th>Description</th>
                <th>Order</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
                  <td>{news.title}</td>
                  <td>{news.description}</td>
                  <td>{news.order}</td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>
                    {' '}
                    <td>
                    <Button color="danger" onClick={() => handleDeleteImage(news.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateImage && (
  <div className="content">
<Form onSubmit={handleEventUpdate}>
  <Row>
    <Col md="7">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          value={updateImage.title}
          onChange={(e) => setupdateImage({ ...updateImage, title: e.target.value })}
        />
      </FormGroup>
    </Col>
    <Col md="6">
      <FormGroup>
        <label>Description:</label>
        <textarea
          type="text"
          name="paragraph"
          value={updateImage.description}
          id="edit-navbar-name"
          onChange={(e) => setupdateImage({ ...updateImage, description: e.target.value })}
          style={{ minHeight: '300px', width: '590px' }}
        />
      </FormGroup>
    </Col>
    <Col md="7">  {/* Add this block for Order */}
      <FormGroup>
        <label>Order:</label>
        <Input
          type="number"
          name="order"
          value={updateImage.order}
          onChange={(e) => setupdateImage({ ...updateImage, order: e.target.value })}
        />
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" type="submit">
          Update What They Say
        </Button>{" "}
        <Button
          className="btn-round"
          color="secondary"
          onClick={() => {
            setshowUpdateImage(false);
            setselectedImageId(null);
          }}
        >
          Cancel
        </Button>
        {updateErrorMessage && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {updateErrorMessage}
          </div>
        )}
        {updateError && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {updateError}
          </div>
        )}
        {updateSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
            {updateSuccessMessage}
          </div>
        )}
        {UpdateRequired && (
          <div style={{ color: 'red', marginTop: '10px' }}>
            {UpdateRequired}
          </div>
        )}
      </div>
    </Col>
  </Row>
</Form>

  </div>
)}

        </CardBody>
      </Card>
    </Col>
  </Row>





<Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this What They Say?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>


</div>


  );
}

export default Footer;