// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';



function Footer() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [NewsSection, setNewsSection] = useState([]);
  const [selectedImageId, setselectedImageId] = useState(null);
  const [updateImage, setupdateImage] = useState({
    image: "",
    headtitle: "",
    titleone: "",
    date: "",
    contentone: "",
    contenttwo: "",
    titletwo: "",
    contentthree: "",
    titlethree: "",
    contentfour: "",
  })
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [newImage, setNewImage] = useState(null);
  const [newHeadTitle, setNewHeadTitle] = useState("");
  const [newTitleOne, setNewTitleOne] = useState("");
  const [newDate, setNewDate] = useState("");
  const [newContentOne, setNewContentOne] = useState("");
  const [newContentTwo, setNewContentTwo] = useState("");
  const [newTitleTwo, setNewTitleTwo] = useState("");
  const [newContentThree, setNewContentThree] = useState("");
  const [newTitleThree, setNewTitleThree] = useState("");
  const [newContentFour, setNewContentFour] = useState("");
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
const [newsToDelete, setNewsToDelete] = useState(null);

const confirmDelete = (id) => {
  setNewsToDelete(id);
  setShowDeleteModal(true);
};

const performDeleteNews = async () => {
  try {
    const response = await axios.delete(`https://back.zubilaw.com/founder/delete/${newsToDelete}`);
    if (response.status === 200) {
      setNewsSection(NewsSection.filter(news => news.id !== newsToDelete));
      setUpdateSuccessMessage('News deleted successfully');
      setTimeout(() => setUpdateSuccessMessage(''), 5000);
    }
  } catch (error) {
    console.error('Error deleting news:', error);
    setUpdateErrorMessage('Failed to delete news. Please try again.');
    setTimeout(() => setUpdateErrorMessage(''), 5000);
  } finally {
    setShowDeleteModal(false);
    setNewsToDelete(null);
  }
};

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];
  

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };





  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/founder/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else 
        console.error("Failed to fetch feature sections:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);

  const handleAddFounder = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('image', newImage);
    formData.append('headtitle', newHeadTitle);
    formData.append('titleone', newTitleOne);
    formData.append('date', newDate);
    formData.append('contentone', newContentOne);
    formData.append('contenttwo', newContentTwo);
    formData.append('titletwo', newTitleTwo);
    formData.append('contentthree', newContentThree);
    formData.append('titlethree', newTitleThree);
    formData.append('contentfour', newContentFour);
  
    try {
      const response = await axios.post('https://back.zubilaw.com/founder/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log("Founder added successfully:", response.data);
      setAddSuccessMessage("Founder added successfully");
      fetchData1(); // Refresh the data
      setTimeout(() => setAddSuccessMessage(""), 5000);
    } catch (error) {
      console.error("Error adding founder:", error);
      setAddError("Error adding founder");
      setTimeout(() => setAddError(""), 5000);
    }
  };
  

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        image: event.image,
        headtitle: event.headtitle,
        titleone: event.titleone,
        date: event.date,
        contentone: event.contentone,
        contenttwo: event.contenttwo,
        titletwo: event.titletwo,
        contentthree: event.contentthree,
        titlethree: event.titlethree,
        contentfour: event.contentfour 

      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

 
  
  


  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (selectedImageId) {
      // Update feature section
      formData.append('headtitle', updateImage.headtitle);
      formData.append('titleone', updateImage.titleone);
      formData.append('date', updateImage.date);
      formData.append('contentone', updateImage.contentone);
      formData.append('contenttwo', updateImage.contenttwo);
      formData.append('titletwo', updateImage.titletwo);
      formData.append('contentthree', updateImage.contentthree);
      formData.append('titlethree', updateImage.titlethree);
      formData.append('contentfour', updateImage.contentfour);
      if (updateImage.image instanceof File) {
        // Assuming isValidImageExtension is defined and checks the file extension
        if (!isValidImageExtension(updateImage.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateImage.image);
      }
  
      try {
        const response = await axios.put(`https://back.zubilaw.com/founder/update/${selectedImageId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Feature section updated successfully:", response.data);
        fetchData1(); // Assuming fetchData1 fetches the feature sections
        setUpdateSuccessMessage("Feature section updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setshowUpdateImage(false);
      } catch (error) {
        console.error("Error updating feature section:", error);
        setUpdateError("Error updating feature section");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://back.zubilaw.com/founder/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/founder/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("Background image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating background image:", error);
                setUpdateError("Error updating background image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for background image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};




  return (
   











<div className="content">
  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>
        <Form onSubmit={handleAddFounder}>
  <Row>

    <Col md="7">
    <FormGroup>
    <label>Paragraph:</label>
    <div style={{ height: '300px', overflowY: 'auto' }}>
          <ReactQuill 
            value={newTitleOne} 
            onChange={setNewTitleOne} 
            modules={modules} 
            formats={formats} 
            style={{ height: '80%', color: 'black' }} // Directly set the color to black
          />
        </div>
</FormGroup>

    </Col>

    <Col md="12">
      <Button className="btn-round" color="primary" type="submit">
        Add Founder
      </Button>
      {addSuccessMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {addSuccessMessage}
        </div>
      )}
      {addError && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {addError}
        </div>
      )}
    </Col>
  </Row>
</Form>


          <Table responsive>
            <thead className="text-primary">
              <tr>
       
                <th>Paragraph</th>

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
  
                  <td dangerouslySetInnerHTML={{ __html: news.titleone}}></td>

                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>{" "}
                    <td>
                    <Button color="danger" onClick={() => confirmDelete(news.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
  

        <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div style={{ height: '300px', overflowY: 'auto' }}>
      <ReactQuill
        value={updateImage.titleone}
        onChange={(value) => setupdateImage({ ...updateImage, titleone: value })}
        modules={modules} 
        formats={formats}
        style={{ height: '80%', color: 'black' }} // Directly set the color to black
      />
    </div>
  </FormGroup>
</Col>



            </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update News
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

<Modal isOpen={showDeleteModal} toggle={() => setShowDeleteModal(false)}>
  <ModalHeader toggle={() => setShowDeleteModal(false)}>
    Confirm Delete
  </ModalHeader>
  <ModalBody>Are you sure you want to delete this news?</ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDeleteNews}>
      Delete
    </Button>{" "}
    <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>


        </CardBody>
      </Card>
    </Col>
  </Row>
</div>



















  );
}

export default Footer;
