import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";

function Lawyers() {
  const [NewsSection, setNewsSection] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [addImage, setAddImage] = useState({
    image: "",
    headtitle: "",
    title: "",
    paragraph: "",
    content: "",
    order: "",
  });
  
  const [updateImage, setUpdateImage] = useState({
    image: "",
    headtitle: "",
    title: "",
    paragraph: "",
    content: "",
    order: "",
  });
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [newOrder, setNewOrder] = useState("");


  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const fetchData1 = async () => {
    setLoading(true);
    try {
      const response = await axios.get("https://back.zubilaw.com/ourteampartners/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
      } else {
        console.error("Failed to fetch partners:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData1();
  }, []);

  const handleAddService = async () => {
    const headtitle = addImage.headtitle;
    const title = addImage.title;
    const paragraph = addImage.paragraph;
    const content = addImage.content;
    const order = addImage.order;
    const mainImageInput = document.getElementById('newServiceMainImage');
    const errors = {};
  
    if (!headtitle) errors.headtitle = 'Head Title is required';
    if (!title) errors.title = 'Title is required';
    if (!paragraph) errors.paragraph = 'Paragraph is required';
    if (!content) errors.content = 'Content is required';
    if (!order) errors.order = 'Order is required';
    if (!mainImageInput.files[0]) {
      errors.image = 'Main Image is required';
    } else if (!isValidImageExtension(mainImageInput.files[0].name)) {
      errors.image = 'Invalid main image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.';
    }
  
    setFieldErrors(errors);
  
    if (Object.keys(errors).length > 0) {
      return;
    }
  
    const formData = new FormData();
    formData.append('headtitle', headtitle);
    formData.append('title', title);
    formData.append('paragraph', paragraph);
    formData.append('content', content);
    formData.append('order', order);
    formData.append('image', mainImageInput.files[0]);
  
    try {
      const response = await axios.post('https://back.zubilaw.com/ourteampartners/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('Partners added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData1();
        setAddImage({
          image: "",
          headtitle: "",
          title: "",
          paragraph: "",
          content: "",
          order: "",
        });
        mainImageInput.value = '';
      }
    } catch (error) {
      console.error('Error adding partners:', error);
      setAddError('Failed to add partners. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  
  

  
  

  const handleUpdate1 = (event) => {
    if (event.id) {
      setSelectedImageId(event.id);
      setUpdateImage({
        headtitle: event.headtitle,
        title: event.title,
        paragraph: event.paragraph,
        content: event.content,
        order: event.order,
        image: event.image
      });
      setShowUpdateImage(true);
    } else {
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    if (selectedImageId) {
        formData.append('headtitle', updateImage.headtitle);
        formData.append('title', updateImage.title);
        formData.append('paragraph', updateImage.paragraph);
        formData.append('content', updateImage.content);
        formData.append('order', updateImage.order); // Add this line
        if (updateImage.image instanceof File) {
            if (!isValidImageExtension(updateImage.image.name)) {
                setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }
            formData.append('image', updateImage.image);
        }

        try {
            const response = await axios.put(`https://back.zubilaw.com/ourteampartners/update/${selectedImageId}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            console.log("partners updated successfully:", response.data);
            fetchData1();
            setUpdateSuccessMessage("Partners updated successfully");
            setTimeout(() => setUpdateSuccessMessage(""), 5000);
            setShowUpdateImage(false);
        } catch (error) {
            console.error("Error updating partners:", error);
            setUpdateError("Error updating partners");
            setTimeout(() => setUpdateError(""), 5000);
        }
    } else {
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://back.zubilaw.com/ourteampartners/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/ourteampartners/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("Partners image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);

            } catch (error) {
                console.error("Error updating partners image:", error);
                setUpdateError("Error updating partners image");
                setTimeout(() => setUpdateError(""), 5000);

            }
        } else {
            setUpdateError("Invalid image file extension for partners image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};

  

  const handleDeleteImage = (id) => {
    setSelectedImageId(id);
    setShowDeleteNavbarAboutPopup(true);
  };

  const performDeleteNavbarAbout = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/ourteampartners/delete/${selectedImageId}`);
      if (response.status === 200) {
        const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
        setNewsSection(updatedNavbarAboutItems);
        setShowDeleteNavbarAboutPopup(false);
      } else {
        console.error('Failed to delete partners item');
      }
    } catch (error) {
      console.error('Error deleting partners item:', error);
    }
  };

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Partners</CardTitle>
            </CardHeader>
            <CardBody>
            <Form>
  <Row>
    <Col md="7">
      <FormGroup>
        <label>Name:</label>
        <Input
          type="text"
          name="headtitle"
          id="newServiceheadtitle"
          value={addImage.headtitle}
          onChange={(e) => setAddImage({ ...addImage, headtitle: e.target.value })}
        />
        {fieldErrors.headtitle && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.headtitle}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Position:</label>
        <Input
          type="text"
          name="title"
          id="newServicetitle"
          value={addImage.title}
          onChange={(e) => setAddImage({ ...addImage, title: e.target.value })}
        />
        {fieldErrors.title && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.title}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Order:</label>
        <Input
          type="number"
          name="order"
          id="newServiceOrder"
          value={addImage.order}
          onChange={(e) => setAddImage({ ...addImage, order: e.target.value })}
        />
        {fieldErrors.order && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.order}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label htmlFor="newServiceparagraph" className="textarea-label">Description:</label>
        <div
          style={{
            height: '300px',
            overflowY: 'auto',
          }}
        >
          <ReactQuill
            value={addImage.paragraph}
            onChange={(html) => setAddImage({ ...addImage, paragraph: html })}
            modules={modules}
            formats={formats}
            style={{ height: '80%', color: 'black' }}
          />
        </div>
        {fieldErrors.paragraph && <div style={{ color: 'red', marginTop: '5px' }}>{fieldErrors.paragraph}</div>}
      </FormGroup>
    </Col>
    <Col md="7">
      <FormGroup>
        <label>Content:</label>
        <div
          style={{
            height: '300px',
            overflowY: 'auto',
          }}
        >
          <ReactQuill
            value={addImage.content}
            onChange={(html) => setAddImage({ ...addImage, content: html })}
            modules={modules}
            formats={formats}
            style={{ height: '80%', color: 'black' }}
          />
        </div>
        {fieldErrors.content && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.content}</div>}
      </FormGroup>
    </Col>
    <Col md="8">
      <FormGroup>
        <label>Image:</label>
        <Input type="file" name="mainImage" id="newServiceMainImage" />
        {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
      </FormGroup>
    </Col>
  </Row>
  <Row>
    <Col md="12">
      <div className="update ml-auto mr-auto">
        <Button className="btn-round" color="primary" onClick={handleAddService}>
          Add Partners
        </Button>
        {addError && <div style={{ color: "red", marginTop: "10px" }}>{addError}</div>}
        {addSuccessMessage && (
          <div className="alert alert-success" role="alert" style={{ width: "50%", margin: "10px auto", textAlign: "center" }}>
            {addSuccessMessage}
          </div>
        )}
      </div>
    </Col>
  </Row>
</Form>

              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Image</th>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Paragraph</th>
                    <th>Content</th>
                    <th>Order</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {NewsSection.map((news, index) => (
                    <tr key={index}>
                      <td>
                        <img src={`https://back.zubilaw.com/${news.image}`} alt="Icon" style={{ width: '200px' }} />
                      </td>
                      <td>{news.headtitle}</td>
                      <td>{news.title}</td>
                      <td dangerouslySetInnerHTML={{ __html: news.paragraph}}></td>
                      <td dangerouslySetInnerHTML={{ __html: news.content}}></td>
                      <td>{news.order}</td>
                      <td>
                        <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                      </td>
                      {' '}
                      <td>
                        <Button color="danger" onClick={() => handleDeleteImage(news.id)}>Delete</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Head Title:</label>
            <Input
              type="text"
              name="title"
              value={updateImage.headtitle}
              onChange={(e) => setUpdateImage({ ...updateImage, headtitle: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Title:</label>
            <Input
              type="text"
              name="paragraph"
              value={updateImage.title}
              id="edit-navbar-name"
              onChange={(e) => setUpdateImage({ ...updateImage, title: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
    <FormGroup>
        <label>Order:</label>
        <Input
            type="number"
            name="order"
            value={updateImage.order}
            onChange={(e) => setUpdateImage({ ...updateImage, order: e.target.value })}
        />
    </FormGroup>
</Col>

        <Col md="7">
          <FormGroup>
            <label>Paragraph:</label>
            <div
              style={{
                height: '300px', // Adjust the height as needed
                overflowY: 'auto', // Add a vertical scrollbar when content overflows
              }}
            >
              <ReactQuill
                value={updateImage.paragraph} // Initial value for ReactQuill
                onChange={(html) => setUpdateImage({ ...updateImage, paragraph: html })}
                modules={modules} 
                formats={formats}
                style={{ height: '80%', color: 'black' }}
              />
            </div>
          </FormGroup>
        </Col>
        <Col md="7">
  <FormGroup>
    <label>Content:</label>
    <div
    style={{
      height: '300px',
      overflowY: 'auto',
    }}
  >
    <ReactQuill
      value={updateImage.content}
      onChange={(html) => setUpdateImage({ ...updateImage, content: html })}
      modules={modules}
      formats={formats}
      style={{ height: '80%', color: 'black' }}
    />
     </div>
  </FormGroup>
</Col>

        <Col md="7">
          <FormGroup>
            {updateImage.image && (
              <div>
                <label>Current Image:</label>
                <div>
                  <img src={`https://back.zubilaw.com/${updateImage.image}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
                </div>
              </div>
            )}
            <label className="mt-3">Upload New Image:</label>
            <Input
              type="file"
              name="image"
              onChange={(e) => setUpdateImage({ ...updateImage, image: e.target.files[0] })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Partners
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateImage(false);
                setSelectedImageId(null);
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateErrorMessage}
              </div>
            )}
            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}


            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
        <ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this partners item?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
          <Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default Lawyers;
