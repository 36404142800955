import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const PublicationsCMS = () => {
  const [imagePublications, setImagePublications] = useState([]);
  const [textPublications, setTextPublications] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newText, setNewText] = useState('');
  const [addSuccessMessage, setAddSuccessMessage] = useState('');
  const [addError, setAddError] = useState('');
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [publicationToDelete, setPublicationToDelete] = useState(null);
  const [editingImagePublication, setEditingImagePublication] = useState(null);
  const [editingTextPublication, setEditingTextPublication] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    fetchPublicationsImages();
    fetchPublicationsTexts();
  }, []);

  const fetchPublicationsImages = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/SliderPublication/listImages');
      setImagePublications(response.data.data);
    } catch (error) {
      console.error('Error fetching publications images:', error);
    }
  };

  const fetchPublicationsTexts = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/SliderPublication/listTexts');
      setTextPublications(response.data.data);
    } catch (error) {
      console.error('Error fetching publications texts:', error);
    }
  };

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const addPublicationImage = async () => {
    const errors = {};
    if (!newImage) {
      errors.image = 'Please select an image to upload.';
    } else if (!isValidImageExtension(newImage.name)) {
      errors.image = 'Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.';
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.post('https://back.zubilaw.com/SliderPublication/addImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAddSuccessMessage('Publication image added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewImage(null);
      fetchPublicationsImages();
    } catch (error) {
      setAddError('Error adding publication image.');
      setTimeout(() => {
        setAddError('');
      }, 5000);
      console.error('Error adding publication image:', error);
    }
  };

  const addPublicationText = async () => {
    const data = {
      text: newText,
    };

    try {
      await axios.post('https://back.zubilaw.com/SliderPublication/addText', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setAddSuccessMessage('Publication text added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewText('');
      fetchPublicationsTexts();
    } catch (error) {
      setAddError('Please enter text to add.');
      console.error('Error adding publication text:', error);
    }
  };

  const updatePublicationImage = async (id) => {
    const errors = {};
    if (!newImage) {
      errors.image = 'Please select an image to upload.';
    } else if (!isValidImageExtension(newImage.name)) {
      errors.image = 'Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.';
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.put(`https://back.zubilaw.com/SliderPublication/updateImage/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUpdateSuccessMessage('Publication image updated successfully!');
      setEditingImagePublication(null);
      setNewImage(null);
      fetchPublicationsImages();
    } catch (error) {
      setUpdateError('Error updating publication image.');
      console.error('Error updating publication image:', error);
    }
  };

  const updatePublicationText = async (id) => {
    const data = {
      text: newText,
    };

    try {
      await axios.put(`https://back.zubilaw.com/SliderPublication/updateText/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUpdateSuccessMessage('Publication text updated successfully!');
      setEditingTextPublication(null);
      setNewText('');
      fetchPublicationsTexts();
    } catch (error) {
      setUpdateError('Error updating publication text.');
      console.error('Error updating publication text:', error);
    }
  };

  const deletePublication = (id) => {
    setPublicationToDelete(id);
    setShowDeletePopup(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`https://back.zubilaw.com/SliderPublication/delete/${publicationToDelete}`);
      setShowDeletePopup(false);
      fetchPublicationsImages();
      fetchPublicationsTexts();
    } catch (error) {
      console.error('Error deleting publication:', error);
    }
  };

  const closeUpdateForm = () => {
    setEditingImagePublication(null);
    setEditingTextPublication(null);
    setNewImage(null);
    setNewText('');
  };

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Manage Publications Images</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Image</label>
                      <Input
                        type="file"
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addPublicationImage}
                    >
                      Add Publication Image
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div className="alert alert-danger" role="alert">
                        {addError}
                      </div>
                    )}
                    {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
                  </Col>
                </Row>
              </Form>
              <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {imagePublications.map((publication, index) => (
      <tr key={publication.id}>
        <td>
          <img
            src={`https://back.zubilaw.com/${publication.slider}`}
            alt="Publication Slider"
            style={{ maxWidth: '300px', maxHeight: '200px' }}
          />
        </td>
        <td>
          <Button
            color="success"
            onClick={() => {
              setEditingImagePublication(publication);
              setNewImage(null);
            }}
          >
            Update Image
          </Button>{" "}
          {index !== 0 && (
            <Button
              color="danger"
              onClick={() => deletePublication(publication.id)}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Text Details</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Text</label>
                      <Input
                        type="text"
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addPublicationText}
                    >
                      Add Publication Text
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div className="alert alert-danger" role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form> */}
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Text</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {textPublications.map((publication) => (
                    <tr key={publication.id}>
                      <td dangerouslySetInnerHTML={{ __html: publication.text }}></td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => {
                            setEditingTextPublication(publication);
                            setNewText(publication.text);
                          }}
                        >
                          Update Text
                        </Button>{" "}
                        {/* <Button
                          color="danger"
                          onClick={() => deletePublication(publication.id)}
                        >
                          Delete
                        </Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {(editingImagePublication || editingTextPublication) && (
        <CardFooter>
          <Form>
            <Row>
              {editingTextPublication && (
                <Col md="7">
                  <FormGroup>
                    <label>Text</label>
                    <div
                      style={{
                        height: '300px',
                        overflowY: 'auto',
                      }}
                    >
                      <ReactQuill
                        value={newText}
                        onChange={setNewText}
                        modules={modules} 
                        formats={formats}
                        style={{ height: '80%', color: 'black' }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              {editingImagePublication && (
                <Col md="6">
                  <FormGroup>
                    <label>Image</label>
                    <Input
                      type="file"
                      onChange={(e) => setNewImage(e.target.files[0])}
                    />
                    {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
                  </FormGroup>
                </Col>
              )}
              <Col md="12">
                {editingTextPublication && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updatePublicationText(editingTextPublication.id)}
                  >
                    Update Text
                  </Button>
                )}{" "}
                {editingImagePublication && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updatePublicationImage(editingImagePublication.id)}
                  >
                    Update Image
                  </Button>
                )}{" "}
                <Button
                  className="btn-round"
                  color="secondary"
                  onClick={closeUpdateForm}
                >
                  Cancel
                </Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateError}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
      )}

      <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
        <ModalHeader toggle={() => setShowDeletePopup(false)}>Delete Publication</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this publication?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDelete}>Delete</Button>
          <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PublicationsCMS;
