import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const WhatTheySaySlider = () => {
  const [imageWhatTheySay, setImageWhatTheySay] = useState([]);
  const [textWhatTheySay, setTextWhatTheySay] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newText, setNewText] = useState('');
  const [addSuccessMessage, setAddSuccessMessage] = useState('');
  const [addError, setAddError] = useState('');
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [whatTheySayToDelete, setWhatTheySayToDelete] = useState(null);
  const [editingImageWhatTheySay, setEditingImageWhatTheySay] = useState(null);
  const [editingTextWhatTheySay, setEditingTextWhatTheySay] = useState(null);
  const [fieldErrors, setFieldErrors] = useState({});

  useEffect(() => {
    fetchWhatTheySayImages();
    fetchWhatTheySayTexts();
  }, []);

  const fetchWhatTheySayImages = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/sliderWhatTheySay/listImages');
      setImageWhatTheySay(response.data.data);
    } catch (error) {
      console.error('Error fetching Pro Bono images:', error);
    }
  };

  const fetchWhatTheySayTexts = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/sliderWhatTheySay/listTexts');
      setTextWhatTheySay(response.data.data);
    } catch (error) {
      console.error('Error fetching Pro Bono texts:', error);
    }
  };

  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };

  const addWhatTheySayImage = async () => {
    const errors = {};
    if (!newImage) {
      errors.image = 'Please select an image to upload.';
    } else if (!isValidImageExtension(newImage.name)) {
      errors.image = 'Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.';
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.post('https://back.zubilaw.com/sliderWhatTheySay/addImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAddSuccessMessage('Pro Bono image added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewImage(null);
      fetchWhatTheySayImages();
    } catch (error) {
      setAddError('Error adding Pro Bono image.');
      setTimeout(() => {
        setAddError('');
      }, 5000);
      console.error('Error adding Pro Bono image:', error);
    }
  };

  const addWhatTheySayText = async () => {
    const data = {
      text: newText,
    };

    try {
      await axios.post('https://back.zubilaw.com/sliderWhatTheySay/addText', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setAddSuccessMessage('Pro Bono text added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewText('');
      fetchWhatTheySayTexts();
    } catch (error) {
      setAddError('Please enter text to add.');
      console.error('Error adding Pro Bono text:', error);
    }
  };

  const updateWhatTheySayImage = async (id) => {
    const errors = {};
    if (!newImage) {
      errors.image = 'Please select an image to upload.';
    } else if (!isValidImageExtension(newImage.name)) {
      errors.image = 'Invalid image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.';
    }

    setFieldErrors(errors);

    if (Object.keys(errors).length > 0) {
      return;
    }

    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.put(`https://back.zubilaw.com/sliderWhatTheySay/updateImage/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUpdateSuccessMessage('Pro Bono image updated successfully!');
      setEditingImageWhatTheySay(null);
      setNewImage(null);
      fetchWhatTheySayImages();
    } catch (error) {
      setUpdateError('Error updating Pro Bono image.');
      console.error('Error updating Pro Bono image:', error);
    }
  };

  const updateWhatTheySayText = async (id) => {
    const data = {
      text: newText,
    };

    try {
      await axios.put(`https://back.zubilaw.com/sliderWhatTheySay/updateText/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUpdateSuccessMessage('Pro Bono text updated successfully!');
      setEditingTextWhatTheySay(null);
      setNewText('');
      fetchWhatTheySayTexts();
    } catch (error) {
      setUpdateError('Error updating Pro Bono text.');
      console.error('Error updating Pro Bono text:', error);
    }
  };

  const deleteWhatTheySay = (id) => {
    setWhatTheySayToDelete(id);
    setShowDeletePopup(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`https://back.zubilaw.com/sliderWhatTheySay/delete/${whatTheySayToDelete}`);
      setShowDeletePopup(false);
      fetchWhatTheySayImages();
      fetchWhatTheySayTexts();
    } catch (error) {
      console.error('Error deleting Pro Bono:', error);
    }
  };

  const closeUpdateForm = () => {
    setEditingImageWhatTheySay(null);
    setEditingTextWhatTheySay(null);
    setNewImage(null);
    setNewText('');
  };

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Manage Pro Bono Images</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Image</label>
                      <Input
                        type="file"
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addWhatTheySayImage}
                    >
                      Add Pro Bono Image
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div className="alert alert-danger" role="alert">
                        {addError}
                      </div>
                    )}
                    {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
                  </Col>
                </Row>
              </Form>
              <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {imageWhatTheySay.map((whatTheySay, index) => (
      <tr key={whatTheySay.id}>
        <td>
          <img
            src={`https://back.zubilaw.com/${whatTheySay.slider}`}
            alt="Pro Bono Slider"
            style={{ maxWidth: '300px', maxHeight: '200px' }}
          />
        </td>
        <td>
          <Button
            color="success"
            onClick={() => {
              setEditingImageWhatTheySay(whatTheySay);
              setNewImage(null);
            }}
          >
            Update Image
          </Button>{" "}
          {index !== 0 && (
            <Button
              color="danger"
              onClick={() => deleteWhatTheySay(whatTheySay.id)}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Text Details</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Text</label>
                      <Input
                        type="text"
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addWhatTheySayText}
                    >
                      Add Pro Bono Text
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div className="alert alert-danger" role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form> */}
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Text</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {textWhatTheySay.map((whatTheySay) => (
                    <tr key={whatTheySay.id}>
                      <td dangerouslySetInnerHTML={{ __html: whatTheySay.text }}></td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => {
                            setEditingTextWhatTheySay(whatTheySay);
                            setNewText(whatTheySay.text);
                          }}
                        >
                          Update Text
                        </Button>{" "}
                        {/* <Button
                          color="danger"
                          onClick={() => deleteWhatTheySay(whatTheySay.id)}
                        >
                          Delete
                        </Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {(editingImageWhatTheySay || editingTextWhatTheySay) && (
        <CardFooter>
          <Form>
            <Row>
              {editingTextWhatTheySay && (
                <Col md="7">
                  <FormGroup>
                    <label>Text</label>
                    <div
                      style={{
                        height: '300px',
                        overflowY: 'auto',
                      }}
                    >
                      <ReactQuill
                        value={newText}
                        onChange={setNewText}
                        modules={modules} 
                        formats={formats}
                        style={{ height: '80%', color: 'black' }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              {editingImageWhatTheySay && (
                <Col md="6">
                  <FormGroup>
                    <label>Image</label>
                    <Input
                      type="file"
                      onChange={(e) => setNewImage(e.target.files[0])}
                    />
                    {fieldErrors.image && <div style={{ color: "red", marginTop: "5px" }}>{fieldErrors.image}</div>}
                  </FormGroup>
                </Col>
              )}
              <Col md="12">
                {editingTextWhatTheySay && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateWhatTheySayText(editingTextWhatTheySay.id)}
                  >
                    Update Text
                  </Button>
                )}{" "}
                {editingImageWhatTheySay && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateWhatTheySayImage(editingImageWhatTheySay.id)}
                  >
                    Update Image
                  </Button>
                )}{" "}
                <Button
                  className="btn-round"
                  color="secondary"
                  onClick={closeUpdateForm}
                >
                  Cancel
                </Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateError}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
      )}

      <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
        <ModalHeader toggle={() => setShowDeletePopup(false)}>Delete Pro Bono</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this Pro Bono?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDelete}>Delete</Button>
          <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default WhatTheySaySlider;
