// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Footer() {
  const [terms, setTerms] = useState([]);
  const [showDeleteProfileModal, setShowDeleteProfileModal] = useState(false);
  const [profileToDelete, setProfileToDelete] = useState(null);  
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [newsToDelete, setNewsToDelete] = useState(null);  
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
 
    paragraph: "",
    imageone: "",

  });
  const [newParagraph, setNewParagraph] = useState("");
const [showAddForm, setShowAddForm] = useState(false);
const [addSuccessMessage, setAddSuccessMessage] = useState("");
const [addErrorMessage, setAddErrorMessage] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [NewsSection, setNewsSection] = useState([]);
  const [selectedImageId, setselectedImageId] = useState(null);
  const [updateImage, setupdateImage] = useState({
    image: "",
    title: "",
    paragraph: "",
    content: "",
  })
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [UpdateRequired, setUpdateRequired] = useState("");




  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };





  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/backgroundpropile/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else 
        console.error("Failed to fetch feature sections:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);





  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        title: event.title,
        paragraph: event.paragraph,
        content: event.content,
        image: event.image // Prepare to update the icon image
      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

 
  
  
  const handleAddParagraph = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://back.zubilaw.com/backgroundpropile/add', {
        paragraph: newParagraph
      });
      if (response.data.success) {
        setAddSuccessMessage("Paragraph added successfully");
        setTimeout(() => setAddSuccessMessage(""), 5000);
        fetchData1(); // Refresh the data
        setShowAddForm(false);
        setNewParagraph("");
      } else {
        setAddErrorMessage("Failed to add paragraph. Please try again.");
        setTimeout(() => setAddErrorMessage(""), 5000);
      }
    } catch (error) {
      console.error('Error adding paragraph:', error);
      setAddErrorMessage('Failed to add paragraph. Please try again.');
      setTimeout(() => setAddErrorMessage(""), 5000);
    }
  };
  
  const handleDelete1 = (newsId) => {
    setNewsToDelete(newsId);
    setShowDeleteModal(true);
  };

  const performDeleteNews = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/backgroundpropile/delete/${newsToDelete}`);
      if (response.status === 200) {
        setNewsSection(NewsSection.filter((news) => news.id !== newsToDelete));
        setSuccessMessage('News deleted successfully');
        setTimeout(() => setSuccessMessage(''), 5000);
      }
    } catch (error) {
      console.error('Error deleting news:', error);
      setUpdateErrorMessage('Failed to delete news. Please try again.');
      setTimeout(() => setUpdateErrorMessage(''), 5000);
    } finally {
      setShowDeleteModal(false);
      setNewsToDelete(null);
    }
  };
  
  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (selectedImageId) {
      // Update feature section
      formData.append('title', updateImage.title);
      formData.append('paragraph', updateImage.paragraph);
      formData.append('content', updateImage.content);
      if (updateImage.image instanceof File) {
        // Assuming isValidImageExtension is defined and checks the file extension
        if (!isValidImageExtension(updateImage.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateImage.image);
      }
  
      try {
        const response = await axios.put(`https://back.zubilaw.com/backgroundpropile/update/${selectedImageId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Feature section updated successfully:", response.data);
        fetchData1(); // Assuming fetchData1 fetches the feature sections
        setUpdateSuccessMessage("Feature section updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setshowUpdateImage(false);
      } catch (error) {
        console.error("Error updating feature section:", error);
        setUpdateError("Error updating feature section");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://back.zubilaw.com/backgroundpropile/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/backgroundpropile/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("Background image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating background image:", error);
                setUpdateError("Error updating background image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for background image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};





  

const fetchData = async () => {
  try {
    const response = await axios.get("https://back.zubilaw.com/Profile/list");
    if (response.data && response.data.success) {
      setTerms(response.data.data); // Wrapping the object in an array
      setTermCount(1); // Since it's a single object
    } else {
      console.error("Unexpected response format:", response.data);
      // Handle this situation appropriately
    }
  } catch (error) {
    console.error(`Error getting data: ${error}`);
    setLoading(false);
    setError("Error fetching data. Please try again later.");
  }
};

  
  
  const handleAddProfile = async () => {
    const formData = new FormData();
    formData.append('title', updateTerm.title);
    formData.append('paragraph', updateTerm.paragraph);
    formData.append('contentone', updateTerm.contentone);
    formData.append('contenttwo', updateTerm.contenttwo);
    formData.append('contentthree', updateTerm.contentthree);
    formData.append('contentfour', updateTerm.contentfour);
    if (updateTerm.imageone) formData.append('imageone', updateTerm.imageone);
    if (updateTerm.imagetwo) formData.append('imagetwo', updateTerm.imagetwo);
    if (updateTerm.imagethree) formData.append('imagethree', updateTerm.imagethree);
    if (updateTerm.imagefour) formData.append('imagefour', updateTerm.imagefour);
  
    try {
      const response = await axios.post('https://back.zubilaw.com/Profile/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      if (response.status === 200 || response.status === 201) {
        setSuccessMessage('Profile added successfully');
        setTimeout(() => setSuccessMessage(''), 5000);
        fetchData(); // Refresh the data
      }
    } catch (error) {
      console.error('Error adding profile:', error);
      setUpdateErrorMessage('Failed to add profile. Please try again.');
      setTimeout(() => setUpdateErrorMessage(''), 5000);
    }
  };
  

  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      paragraph: term.paragraph,
      imageone: term.imageone, // Keep existing image data
    });
    setShowUpdateForm(true);
  };
  
  
  

  const handleTermUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    // Preserve paragraph if no new data is provided
    if (updateTerm.paragraph) {
      formData.append('paragraph', updateTerm.paragraph);
    } else {
      formData.append('paragraph', terms.find(term => term.id === selectedTermId).paragraph);
    }
  
    // Append imageone only if a new file is provided
    if (updateTerm.imageone instanceof File) {
      formData.append('imageone', updateTerm.imageone);
    } else {
      // If no new file is selected, preserve the existing image URL
      formData.append('existingImage', terms.find(term => term.id === selectedTermId).imageone);
    }
  
    try {
      const response = await axios.put(
        `https://back.zubilaw.com/profile/update/${selectedTermId}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      );
  
      if (response.status === 200) {
        console.log("Profile updated successfully:", response.data);
        fetchData();
        setUpdateTerm({ paragraph: "", imageone: "" });
        setSelectedTermId(null);
        setSuccessMessage("Profile updated successfully");
        setUpdateErrorMessage(""); // Clear any existing error message
        setShowUpdateForm(false); // Hide the update form
        setTimeout(() => {
          setSuccessMessage("");
        }, 5000);
      }
    } catch (error) {
      console.error("Error updating Profile:", error);
      setUpdateErrorMessage("Failed to update Profile. Please try again.");
    }
  };
  
  
  
  
  

  const handleDelete = (id) => {
    setProfileToDelete(id);
    setShowDeleteProfileModal(true);
  };
  
  const performDeleteProfile = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/profile/delete/${profileToDelete}`);
      if (response.status === 200) {
        setTerms(terms.filter((term) => term.id !== profileToDelete));
        setSuccessMessage('Profile deleted successfully');
        setTimeout(() => setSuccessMessage(''), 5000);
      }
    } catch (error) {
      console.error('Error deleting profile:', error);
      setError('Failed to delete profile. Please try again.');
      setTimeout(() => setError(''), 5000);
    } finally {
      setShowDeleteProfileModal(false);
      setProfileToDelete(null);
    }
  };
  
  


  // useEffect(() => {
  //   fetchData();
  //   if (showUpdateForm) {
  //     // Assuming the first input field has an id 'update-title'
  //     document.getElementById('update-title').focus();
  //   }
  // }, [showUpdateForm]);


  useEffect(() => {
    fetchData();
  }, []);
  


  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];





  return (
    <div className="content">
  <div >

  
        
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">Profile Page</CardTitle>
                </CardHeader>
                <CardBody>
                <Form>
  <Row>
    {/* <Col md="6">
      <FormGroup>
        <label>Title:</label>
        <Input
          type="text"
          name="title"
          value={updateTerm.title}
          onChange={(e) => setUpdateTerm({ ...updateTerm, title: e.target.value })}
        />
      </FormGroup>
    </Col> */}

    <Col md="7">
      <FormGroup>
        <label>Paragraph:</label>
        <div
          style={{
            height: '300px', // Adjust the height as needed
            overflowY: 'auto', // Add a vertical scrollbar when content overflows
          }}
        >
          <ReactQuill
            value={updateTerm.paragraph}
            onChange={(value) => setUpdateTerm({ ...updateTerm, paragraph: value })}
            modules={modules}
            formats={formats}
            style={{ height: '80%', color: 'black' }}
          />
        </div>
      </FormGroup>
    </Col>

    {/* <Col md="6">
      <FormGroup>
        <label>Content One:</label>
        <Input
          type="text"
          name="contentone"
          value={updateTerm.contentone}
          onChange={(e) => setUpdateTerm({ ...updateTerm, contentone: e.target.value })}
        />
      </FormGroup>
    </Col>

    <Col md="6">
      <FormGroup>
        <label>Content Two:</label>
        <Input
          type="text"
          name="contenttwo"
          value={updateTerm.contenttwo}
          onChange={(e) => setUpdateTerm({ ...updateTerm, contenttwo: e.target.value })}
        />
      </FormGroup>
    </Col>

    <Col md="6">
      <FormGroup>
        <label>Content Three:</label>
        <Input
          type="text"
          name="contentthree"
          value={updateTerm.contentthree}
          onChange={(e) => setUpdateTerm({ ...updateTerm, contentthree: e.target.value })}
        />
      </FormGroup>
    </Col>

    <Col md="6">
      <FormGroup>
        <label>Content Four:</label>
        <Input
          type="text"
          name="contentfour"
          value={updateTerm.contentfour}
          onChange={(e) => setUpdateTerm({ ...updateTerm, contentfour: e.target.value })}
        />
      </FormGroup>
    </Col> */}

    <Col md="6">
      <FormGroup>
        <label>Image:</label>
        <Input
          type="file"
          name="imageone"
          onChange={(e) => setUpdateTerm({ ...updateTerm, imageone: e.target.files[0] })}
        />
      </FormGroup>
    </Col>

    {/* <Col md="6">
      <FormGroup>
        <label>Image Two:</label>
        <Input
          type="file"
          name="imagetwo"
          onChange={(e) => setUpdateTerm({ ...updateTerm, imagetwo: e.target.files[0] })}
        />
      </FormGroup>
    </Col>

    <Col md="6">
      <FormGroup>
        <label>Image Three:</label>
        <Input
          type="file"
          name="imagethree"
          onChange={(e) => setUpdateTerm({ ...updateTerm, imagethree: e.target.files[0] })}
        />
      </FormGroup>
    </Col>

    <Col md="6">
      <FormGroup>
        <label>Image Four:</label>
        <Input
          type="file"
          name="imagefour"
          onChange={(e) => setUpdateTerm({ ...updateTerm, imagefour: e.target.files[0] })}
        />
      </FormGroup>
    </Col> */}

    <Col md="12">
      <Button className="btn-round" color="primary" onClick={handleAddProfile}>
        Add Profile
      </Button>

      {successMessage && (
        <div
          className="alert alert-success"
          role="alert"
          style={{
            width: "50%",
            margin: "10px auto",
            textAlign: "center",
          }}
        >
          {successMessage}
        </div>
      )}
      {updateErrorMessage && (
        <div style={{ color: "red", marginTop: "10px" }}>{updateErrorMessage}</div>
      )}
    </Col>
  </Row>
</Form>

                  <Table responsive>
                  <thead className="text-primary">
  <tr>
    {/* <th>Title</th> */}
    <th>Paragraph</th>
    {/* <th>Second Paragraph</th>
    <th>Third Paragraph</th>
    <th>Fourth Paragraph</th>
    <th>Fifth Paragraph</th> */}
    <th>Image </th>
    {/* <th>Image Two</th>
    <th>Image Three</th>
    <th>Image Four</th> */}
    <th>Actions</th>
  </tr>
</thead>

<tbody>
  {terms.map((term) => (
    <tr key={term.id}>
      {/* <td>{term.title}</td> */}
      <td dangerouslySetInnerHTML={{ __html: term.paragraph}}></td>
      {/* <td>{term.contentone}</td>
      <td>{term.contenttwo}</td>
      <td>{term.contentthree}</td>
      <td>{term.contentfour}</td> */}
      <td><img src={`https://back.zubilaw.com/${term.imageone}`} alt="Image One" style={{ width: '100px' }} /></td>
      {/* <td><img src={`https://back.zubilaw.com/${term.imagetwo}`} alt="Image Two" style={{ width: '100px' }} /></td>
      <td><img src={`https://back.zubilaw.com/${term.imagethree}`} alt="Image Three" style={{ width: '100px' }} /></td>
      <td><img src={`https://back.zubilaw.com/${term.imagefour}`} alt="Image Four" style={{ width: '100px' }} /></td> */}
     <td>
        <Button 
          color="success"
          onClick={() => handleUpdate(term)}
        >
          Update
        </Button>
        </td>{" "}
        <td>
        <Button 
          color="danger"
          onClick={() => handleDelete(term.id)}
        >
          Delete
        </Button>
      </td>
    </tr>
  ))}
</tbody>

                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

        {showUpdateForm && (
  <div className="content mt-5">
    <Form onSubmit={handleTermUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label htmlFor="content" className="textarea-label">Paragraph:</label>
            <div  style={{
            height: '300px', // Adjust the height as needed
            overflowY: 'auto', // Add a vertical scrollbar when content overflows
          }}>
              <ReactQuill
                value={updateTerm.paragraph}
                onChange={(value) => setUpdateTerm({ ...updateTerm, paragraph: value })}
                modules={modules}
                formats={formats}
                style={{ height: '240px', color: 'black' }} // Adjust the height and styles as needed
              />
            </div>
          </FormGroup>
        </Col>

        <Col md="12">
          <FormGroup>
            <label>Image</label>
            <Input
              type="file"
              name="imageone"
              onChange={(e) => setUpdateTerm({ ...updateTerm, imageone: e.target.files[0] })}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Term
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowUpdateForm(false);
                setSelectedTermId(null);
                setUpdateTerm({
                  paragraph: "",
                  imageone: "",
                });
              }}
            >
              Cancel
            </Button>
            {successMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {successMessage}
              </div>
            )}
            {updateErrorMessage && (
              <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateErrorMessage}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}




<Modal
  isOpen={showDeleteProfileModal}
  toggle={() => setShowDeleteProfileModal(false)}
>
  <ModalHeader toggle={() => setShowDeleteProfileModal(false)}>
    Confirm Delete
  </ModalHeader>
  <ModalBody>Are you sure you want to delete this profile?</ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDeleteProfile}>
      Delete
    </Button>{" "}
    <Button color="secondary" onClick={() => setShowDeleteProfileModal(false)}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>







<div className="content">
  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>
       
  <div className="content">
    <Form onSubmit={handleAddParagraph}>
      <Row>
      <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div  style={{
            height: '300px', // Adjust the height as needed
            overflowY: 'auto', // Add a vertical scrollbar when content overflows
          }}>
      <ReactQuill
        value={newParagraph}
        onChange={(value) => setNewParagraph(value)}
        modules={modules}
        formats={formats}
        style={{ height: '240px', color: 'black' }} // Adjust the height and styles as needed
      />
    </div>
  </FormGroup>
</Col>

      </Row>
      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Add Paragraph
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setShowAddForm(false);
                setNewParagraph("");
              }}
            >
              Cancel
            </Button>
            {addErrorMessage && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {addErrorMessage}
              </div>
            )}
            {addSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {addSuccessMessage}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>



          <Table responsive>
            <thead className="text-primary">
              <tr>
           
                <th>Paragraph</th>
                
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
                 
                  <td dangerouslySetInnerHTML={{ __html: news.paragraph}}></td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>{" "}
                    <td>
                    <Button color="danger" onClick={() => handleDelete1(news.id)}>Delete</Button>

                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
       
      <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div  style={{
            height: '300px', // Adjust the height as needed
            overflowY: 'auto', // Add a vertical scrollbar when content overflows
          }}>
      <ReactQuill
        value={updateImage.paragraph}
        onChange={(value) => setupdateImage({ ...updateImage, paragraph: value })}
        modules={modules}
                formats={formats}
                style={{ height: '240px', color: 'black' }} // Adjust the height and styles as needed
      />
    </div>
  </FormGroup>
</Col>




            </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update News
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

<Modal
  isOpen={showDeleteModal}
  toggle={() => setShowDeleteModal(false)}
>
  <ModalHeader toggle={() => setShowDeleteModal(false)}>
    Confirm Delete
  </ModalHeader>
  <ModalBody>Are you sure you want to delete this news item?</ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDeleteNews}>
      Delete
    </Button>{" "}
    <Button color="secondary" onClick={() => setShowDeleteModal(false)}>
      Cancel
    </Button>
  </ModalFooter>
</Modal>


        </CardBody>
      </Card>
    </Col>
  </Row>
</div>



















    </div>
  );
}

export default Footer;
