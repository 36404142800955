// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    headtitle: "",
    title: "",
    paragraph: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [NewsSection, setNewsSection] = useState([]);
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [selectedImageId, setselectedImageId] = useState(null);
  const [showUpdateImage, setshowUpdateImage] = useState(false);
  const [updateImage, setupdateImage] = useState({
    image: "",
    button: "",
    name: "",
    link: "",
    address: "",
    mail: "",
    mobile: "",
    fax: "",
    email: "",
    website: "",
    map: "",
  })
  const [ImageError, setImageError] = useState("");
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [backgroundImage, setBackgroundImage] = useState(null);



  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };


  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/officesbutton/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else 
        console.error("Failed to fetch Fields of Practice:", response.data.message);
      
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };
  

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);
  



  const handleAddService = async () => {
    // Validate inputs
  const buttonInput = document.getElementById('buttonInput');
  const nameInput = document.getElementById('nameInput');
  const linkInput = document.getElementById('linkInput');
  const addressInput = document.getElementById('addressInput');
  const mailInput = document.getElementById('mailInput');
  const mobileInput = document.getElementById('mobileInput');
  const faxInput = document.getElementById('faxInput');
  const emailInput = document.getElementById('emailInput');
  const websiteInput = document.getElementById('websiteInput');
  const mapInput = document.getElementById('mapInput');
  const mainImageInput = document.getElementById('newServiceMainImage');

  const button = buttonInput.value;
  const name = nameInput.value;
  const link = linkInput.value;
  const address = addressInput.value;
  const mail = mailInput.value;
  const mobile = mobileInput.value;
  const fax = faxInput.value;
  const email = emailInput.value;
  const website = websiteInput.value;
  const map = mapInput.value;
    
  
    if (!button.trim()) {
      setAddError('button is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    if (!name.trim()) {
      setAddError('name is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
    if (!link.trim()) {
        setAddError('link is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
    
      if (!address.trim()) {
        setAddError('address is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
      if (!mail.trim()) {
        setAddError('mail is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
    
      if (!mobile.trim()) {
        setAddError('mobile is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
      if (!fax.trim()) {
        setAddError('fax is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
    
      if (!email.trim()) {
        setAddError('email is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
      if (!website.trim()) {
        setAddError('website is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
    
      if (!map.trim()) {
        setAddError('map is required.');
        setTimeout(() => setAddError(''), 5000);
        return;
      }
  
    if (!mainImageInput.files[0]) {
      setAddError('image is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    } else if (!isValidImageExtension(mainImageInput.files[0].name)) {
      setAddError('Invalid main image file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    
  
    // If all validations pass
    const formData = new FormData();
    formData.append('button', button);
    formData.append('name', name);
    formData.append('link', link);
    formData.append('address', address);
    formData.append('mail', mail);
    formData.append('mobile', mobile);
    formData.append('fax', fax);
    formData.append('email', email);
    formData.append('website', website);
    formData.append('map', map);
    formData.append('image', mainImageInput.files[0]);
  
    try {
      const response = await axios.post('https://back.zubilaw.com/officesbutton/add', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('Associated Offices added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData1();

        buttonInput.value = '';
        nameInput.value = '';
        linkInput.value = '';
        addressInput.value = '';
        mailInput.value = '';
        mobileInput.value = '';
        faxInput.value = '';
        emailInput.value = '';
        websiteInput.value = '';
        mapInput.value = '';
      mainImageInput.value = '';
      }
    } catch (error) {
      console.error('Error adding Associated Offices:', error);
      setAddError('Failed to add Associated Offices. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };


  

  

  const handleUpdate1 = (event) => {
    if (event.id) {
      // For feature sections
      setselectedImageId(event.id);
      setupdateImage({
        button: event.button,
        name: event.name,
        link: event.link,
        address: event.address,
        mail: event.mail,
        mobile: event.mobile,
        fax: event.fax,
        email: event.paragraph,
        website: event.website,
        map: event.map,
        image: event.image // Prepare to update the icon image
      });
      setshowUpdateImage(true);
    } else {
      // For background image
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };

 
  
  


  
  const handleEventUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
  
    if (selectedImageId) {
      // Update feature section
      formData.append('button', updateImage.button);
      formData.append('name', updateImage.name);
      formData.append('link', updateImage.link);
      formData.append('address', updateImage.address);
      formData.append('mail', updateImage.mail);
      formData.append('mobile', updateImage.mobile);
      formData.append('fax', updateImage.fax);
      formData.append('email', updateImage.email);
      formData.append('website', updateImage.website);
      formData.append('map', updateImage.map);
      if (updateImage.image instanceof File) {
        // Assuming isValidImageExtension is defined and checks the file extension
        if (!isValidImageExtension(updateImage.image.name)) {
          setUpdateError("Invalid file extension. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
          setTimeout(() => setUpdateError(""), 5000);
          return;
        }
        formData.append('image', updateImage.image);
      }
  
      try {
        const response = await axios.put(`https://back.zubilaw.com/officesbutton/update/${selectedImageId}`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        console.log("Associated Offices updated successfully:", response.data);
        fetchData1(); // Assuming fetchData1 fetches the Associated Officess
        setUpdateSuccessMessage("Associated Offices updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
        setshowUpdateImage(false);
      } catch (error) {
        console.error("Error updating Associated Offices:", error);
        setUpdateError("Error updating Associated Offices");
        setTimeout(() => setUpdateError(""), 5000);
      }
    } else {
        // Update background image
        if (newBackgroundImage) {
            if (!isValidImageExtension(newBackgroundImage.name)) {
                setUpdateError("Invalid image file extension for background image. Allowed extensions are .webp, .gif, .png, .jpg, .jpeg");
                setTimeout(() => setUpdateError(""), 5000);
                return;
            }

            formData.append('image', newBackgroundImage);

            try {
                const response = await axios.put(`https://back.zubilaw.com/officesbutton/update/1`, formData, {
                    headers: { 'Content-Type': 'multipart/form-data' },
                });

                // Fetch the updated background image URL from the server
                const updatedBgImageResponse = await axios.get("https://back.zubilaw.com/officesbutton/list");
                if (updatedBgImageResponse.data.success) {
                    setBackgroundImage(updatedBgImageResponse.data.data.path);
                }

                setNewBackgroundImage(null);
                setShowImageUpdate(false);
                setUpdateSuccessMessage("image updated successfully");
                setTimeout(() => setUpdateSuccessMessage(""), 5000);
            } catch (error) {
                console.error("Error updating image:", error);
                setUpdateError("Error updating image");
                setTimeout(() => setUpdateError(""), 5000);
            }
        } else {
            setUpdateError("Invalid image file extension for image");
            setTimeout(() => setUpdateError(""), 5000);
        }
    }
};




const handleDeleteImage = (id) => {
    setselectedImageId(id);
    setShowDeleteNavbarAboutPopup(true);
  };
  
  const performDeleteNavbarAbout = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/officesbutton/delete/${selectedImageId}`);
      if (response.status === 200) {
        const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
        setNewsSection(updatedNavbarAboutItems);
        setShowDeleteNavbarAboutPopup(false);
      } else {
        console.error('Failed to delete Associated Offices item');
      }
    } catch (error) {
      console.error('Error deleting Associated Offices item:', error);
    }
  };







  return (
    <div className="content">
        <div className="content">
  <Row>
    <Col md="12">
     
      <Card>

        <CardBody>

        <Form>
    <Row>
      <Col md="5">
        <FormGroup>
          <label>Button:</label>
          <Input type="text" name="button" id="buttonInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Name:</label>
          <Input type="text" name="name" id="nameInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Link:</label>
          <Input type="text" name="link" id="linkInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Address:</label>
          <Input type="text" name="address" id="addressInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Mail:</label>
          <Input type="text" name="mail" id="mailInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Mobile:</label>
          <Input type="text" name="mobile" id="mobileInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Fax:</label>
          <Input type="text" name="fax" id="faxInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Email:</label>
          <Input type="text" name="email" id="emailInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Website:</label>
          <Input type="text" name="website" id="websiteInput" />
        </FormGroup>
      </Col>
      <Col md="5">
        <FormGroup>
          <label>Map:</label>
          <Input type="text" name="map" id="mapInput" />
        </FormGroup>
      </Col>
      <Col md="8">
        <FormGroup>
          <label>Image:</label>
          <Input type="file" name="mainImage" id="newServiceMainImage" />
        </FormGroup>
      </Col>

      <Col md="12">
        <Button className="btn-round" color="primary" onClick={handleAddService}>
          Add Associated Offices
        </Button>

                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div style={{ color: 'red', marginTop: '10px' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>


          <Table responsive>
            <thead className="text-primary">
              <tr>
                <th>Button</th>
                <th>Name</th>
                <th>Link</th>
                <th>Address</th>
                <th>Mail</th>
                <th>Mobile</th>
                <th>Fax</th>
                <th>Email</th>
                <th>Website</th>
                <th>Map</th>
                <th>Image</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {NewsSection.map((news, index) => (
                <tr key={index}>
                  
                  <td>{news.button}</td>
                  <td>{news.name}</td>
                  <td>{news.link}</td>
                  <td>{news.address}</td>
                  <td>{news.mail}</td>
                  <td>{news.mobile}</td>
                  <td>{news.fax}</td>
                  <td>{news.email}</td>
                  <td>{news.website}</td>
                  <td>{news.map}</td>
                  <td>
                    <img src={`https://back.zubilaw.com/${news.image}`} alt="Icon" style={{ width: '200px' }} />
                  </td>
                  <td>
                    <Button color="success" onClick={() => handleUpdate1(news)}>Update</Button>
                    </td>
                    {' '}
                    <td>
                    <Button color="danger" onClick={() => handleDeleteImage(news.id)}>Delete</Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        

          {showUpdateImage && (
  <div className="content">
    <Form onSubmit={handleEventUpdate}>
      <Row>
        <Col md="7">
          <FormGroup>
            <label>Button:</label>
            <Input
              type="text"
              name="button"
              value={updateImage.button}
              
              onChange={(e) => setupdateImage({ ...updateImage, button: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Name:</label>
            <Input
              type="text"
              name="name"
              value={updateImage.name}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, name: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Link:</label>
            <Input
              type="text"
              name="link"
              value={updateImage.link}
              
              onChange={(e) => setupdateImage({ ...updateImage, link: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Address:</label>
            <Input
              type="text"
              name="address"
              value={updateImage.address}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, address: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Mail:</label>
            <Input
              type="text"
              name="mail"
              value={updateImage.mail}
              
              onChange={(e) => setupdateImage({ ...updateImage, mail: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Mobile:</label>
            <Input
              type="text"
              name="mobile"
              value={updateImage.mobile}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, mobile: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Fax:</label>
            <Input
              type="text"
              name="fax"
              value={updateImage.fax}
              
              onChange={(e) => setupdateImage({ ...updateImage, fax: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Email:</label>
            <Input
              type="text"
              name="email"
              value={updateImage.email}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, email: e.target.value })}
            />
          </FormGroup>
        </Col>

        <Col md="7">
          <FormGroup>
            <label>Website:</label>
            <Input
              type="text"
              name="website"
              value={updateImage.website}
              
              onChange={(e) => setupdateImage({ ...updateImage, website: e.target.value })}
            />
          </FormGroup>
        </Col>
        <Col md="7">
          <FormGroup>
            <label>Map:</label>
            <Input
              type="text"
              name="map"
              value={updateImage.map}
              id="edit-navbar-name"
              onChange={(e) => setupdateImage({ ...updateImage, map: e.target.value })}
            />
          </FormGroup>
        </Col>
        
        <Col md="7">
        <FormGroup>
          {updateImage.image && (
            <div>
              <label>Current Image:</label>
              <div>
                <img src={`https://back.zubilaw.com/${updateImage.image}`} alt="Current Icon" style={{ width: '100px', marginBottom: '10px' }} />
              </div>
            </div>
          )}
          <label className="mt-3">Upload New Image:</label>
          <Input
            type="file"
            name="image"
            onChange={(e) => setupdateImage({ ...updateImage, image: e.target.files[0] })}
          />
        </FormGroup>
      </Col>
            </Row>

      <Row>
        <Col md="12">
          <div className="update ml-auto mr-auto">
            <Button className="btn-round" color="primary" type="submit">
              Update Associated Offices
            </Button>{" "}
            <Button
              className="btn-round"
              color="secondary"
              onClick={() => {
                setshowUpdateImage(false);
                setselectedImageId(null);
                
              }}
            >
              Cancel
            </Button>
            {updateErrorMessage && (
    <div style={{ color: 'red', marginTop: '10px' }}>
        {updateErrorMessage}
    </div>
)}

            {updateError && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {updateError}
              </div>
            )}
            {updateSuccessMessage && (
              <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                {updateSuccessMessage}
              </div>
            )}
            {UpdateRequired && (
              <div style={{ color: 'red', marginTop: '10px' }}>
                {UpdateRequired}
              </div>
            )}
          </div>
        </Col>
      </Row>
    </Form>
  </div>
)}

        </CardBody>
      </Card>
    </Col>
  </Row>
</div>




<Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this Associated Offices item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>























    </div>
  );
}

export default Footer;
