import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

function Footer() {
  const [terms, setTerms] = useState([]);
  const [NewsSection, setNewsSection] = useState([]);
  const [NewsSection2, setNewsSection2] = useState([]);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    headtitle: "",
    title: "",
    paragraph: "",
    text: "",
  });
  const [changedFields, setChangedFields] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showImageUpdate, setShowImageUpdate] = useState(false);
  const [showImageUpdate1, setShowImageUpdate1] = useState(false);
  const [selectedImageId, setSelectedImageId] = useState(null);
  const [selectedImageId1, setSelectedImageId1] = useState(null);
  const [updateImage, setUpdateImage] = useState({
    imageUrl: null,
    paragraph: "",
    title: "",
  });
  const [updateImage1, setUpdateImage1] = useState({
    imageUrl: "",
    paragraph: "",
  });
  const [showUpdateImage, setShowUpdateImage] = useState(false);
  const [showUpdateImage1, setShowUpdateImage1] = useState(false);
  const [newBackgroundImage, setNewBackgroundImage] = useState(null);
  const [newBackgroundImage1, setNewBackgroundImage1] = useState(null);
  const [ImageError, setImageError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [UpdateRequired, setUpdateRequired] = useState("");
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [showDeleteNavbarAboutPopup1, setShowDeleteNavbarAboutPopup1] = useState(false);
  const [addError, setAddError] = useState("");
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [title, setTitle] = useState('');
  const [paragraph, setParagraph] = useState('');
  const [title1, setTitle1] = useState('');
  const [paragraph1, setParagraph1] = useState('');
  const [awardsDescriptionList, setAwardsDescriptionList] = useState([]);
  const [addImageError, setAddImageError] = useState("");
  const [addImageSuccessMessage, setAddImageSuccessMessage] = useState("");
  const [newAwardImage, setNewAwardImage] = useState(null);
  const [selectedAwardId, setSelectedAwardId] = useState(null);
  const [showUpdateAwardForm, setShowUpdateAwardForm] = useState(false);
  const [updateImageError, setUpdateImageError] = useState("");
  const [updateImageSuccessMessage, setUpdateImageSuccessMessage] = useState("");
  const [showDeleteAwardModal, setShowDeleteAwardModal] = useState(false);
  const [updateAwardImage, setUpdateAwardImage] = useState(null);

  const fetchAwardsDescriptionList = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/awardsdescription/list");
      if (response.data.success) {
        setAwardsDescriptionList(response.data.data);
      } else {
        console.error("Failed to fetch awards descriptions:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchAwardsDescriptionList();
  }, []);

  const handleAddAwardImage = async () => {
    if (!newAwardImage) {
      setAddImageError('Image is required.');
      setTimeout(() => setAddImageError(''), 5000);
      return;
    }

    const formData = new FormData();
    formData.append('imageUrl', newAwardImage);

    try {
      const response = await axios.post('https://back.zubilaw.com/awardsdescription/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200 || response.status === 201) {
        setAddImageSuccessMessage('Image added successfully');
        setTimeout(() => setAddImageSuccessMessage(''), 5000);
        fetchAwardsDescriptionList();
        setNewAwardImage(null); // Clear the image state
      }
    } catch (error) {
      console.error('Error adding image:', error);
      setAddImageError('Failed to add image. Please try again.');
      setTimeout(() => setAddImageError(''), 5000);
    }
  };

  const handleUpdateAwardImage = async (e) => {
    e.preventDefault();

    if (!updateAwardImage) {
      setUpdateImageError('Image is required.');
      setTimeout(() => setUpdateImageError(''), 5000);
      return;
    }

    const formData = new FormData();
    formData.append('imageUrl', updateAwardImage);

    try {
      const response = await axios.put(`https://back.zubilaw.com/awardsdescription/update/${selectedAwardId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      console.log("Image updated successfully:", response.data);
      await fetchAwardsDescriptionList();
      setUpdateImageSuccessMessage("Image updated successfully");
      setTimeout(() => setUpdateImageSuccessMessage(""), 5000);
      setShowUpdateAwardForm(false);
      setSelectedAwardId(null);
      setUpdateAwardImage(null); // Reset form
    } catch (error) {
      console.error("Error updating image:", error);
      setUpdateImageError("Error updating image: " + (error.response?.data?.errors?.message || "Please try again."));
      setTimeout(() => setUpdateImageError(""), 5000);
    }
  };

  const handleDeleteAwardImage = (id) => {
    setSelectedAwardId(id);
    setShowDeleteAwardModal(true);
  };

  const performDeleteAwardImage = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/awardsdescription/delete/${selectedAwardId}`);
      if (response.status === 200) {
        const updatedDescriptions = awardsDescriptionList.filter(item => item.id !== selectedAwardId);
        setAwardsDescriptionList(updatedDescriptions);
        setShowDeleteAwardModal(false);
      } else {
        console.error('Failed to delete image');
      }
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };


  const fetchData = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/awards/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it's a single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };

  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);


  

  

  
  const fetchData1 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/awardstitle/list");
      if (response.data.success) {
        setNewsSection(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else {
        console.error("Failed to fetch awards:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData1(); // Call fetchNews when the component mounts
  }, []);

  const handleAddService = async () => {
    if (!paragraph.trim()) {
      setAddError('Paragraph is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
    if (!title.trim()) {
      setAddError('Title is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
    if (!newAwardImage) {
      setAddError('Image is required.');
      setTimeout(() => setAddError(''), 5000);
      return;
    }
  
    const formData = new FormData();
    formData.append('title', title); // Add title here
    formData.append('paragraph', paragraph);
    formData.append('imageUrl', newAwardImage);
  
    try {
      const response = await axios.post('https://back.zubilaw.com/awardstitle/add', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      if (response.status === 200 || response.status === 201) {
        setAddSuccessMessage('Awards added successfully');
        setTimeout(() => setAddSuccessMessage(''), 5000);
        fetchData1();
        setTitle('');
        setParagraph('');
        setNewAwardImage(null); // Clear the image state
      }
    } catch (error) {
      console.error('Error adding Awards:', error);
      setAddError('Failed to add Awards. Please try again.');
      setTimeout(() => setAddError(''), 5000);
    }
  };
  
  

  const handleUpdate1 = (event) => {
    if (event.id) {
      setSelectedImageId(event.id);
      setUpdateImage({
        paragraph: event.paragraph,
        title: event.title,
        imageUrl: event.imageUrl
      });
      setShowUpdateImage(true);
    } else {
      setNewBackgroundImage(null);
      setShowImageUpdate(true);
    }
  };
  



  const handleEventUpdate = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('title', updateImage.title); // Add title here
    formData.append('paragraph', updateImage.paragraph);
    if (updateAwardImage) {
      formData.append('imageUrl', updateAwardImage);
    } else if (updateImage.imageUrl) {
      formData.append('imageUrl', updateImage.imageUrl);
    }
  
    try {
      const response = await axios.put(`https://back.zubilaw.com/awardstitle/update/${selectedImageId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log("Awards updated successfully:", response.data);
      await fetchData1();
      setUpdateSuccessMessage("Awards updated successfully");
      setTimeout(() => setUpdateSuccessMessage(""), 5000);
      setShowUpdateImage(false);
      setSelectedImageId(null);
      setUpdateImage({ title: "", paragraph: "", imageUrl: null }); // Reset form
    } catch (error) {
      console.error("Error updating awards:", error.response?.data);
      setUpdateError("Error updating awards: " + (error.response?.data?.errors?.message || "Please try again."));
      setTimeout(() => setUpdateError(""), 5000);
    }
  };
  
  
  

  const handleDeleteImage = (id) => {
    setSelectedImageId(id);
    setShowDeleteNavbarAboutPopup(true);
  };

  const performDeleteNavbarAbout = async () => {
    try {
      const response = await axios.delete(`https://back.zubilaw.com/awardstitle/delete/${selectedImageId}`);
      if (response.status === 200) {
        const updatedNavbarAboutItems = NewsSection.filter(item => item.id !== selectedImageId);
        setNewsSection(updatedNavbarAboutItems);
        setShowDeleteNavbarAboutPopup(false);
      } else {
        console.error('Failed to delete awards');
      }
    } catch (error) {
      console.error('Error deleting awards:', error);
    }
  };

  const fetchData2 = async () => {
    setLoading(true);
    try {
      // Fetch feature section data
      const response = await axios.get("https://back.zubilaw.com/awardsdescription/list");
      if (response.data.success) {
        setNewsSection2(response.data.data);
        // setInfoBarCount(response.data.data.length);
      } else {
        console.error("Failed to fetch awards:", response.data.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setError("Error fetching data. Please try again later.");
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData2(); // Call fetchNews when the component mounts
  }, []);



  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];



  return (
    <div className="content">


     

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Awards Section</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                <Col md="7">
  <FormGroup>
    <label>Title:</label>
    <Input
      type="text"
      value={title}
      onChange={(e) => setTitle(e.target.value)} // Corrected here
    />
  </FormGroup>
</Col>


                <Col md="7">
  <FormGroup>
    <label>Paragraph:</label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
      <ReactQuill
        value={paragraph}
        onChange={setParagraph}
        modules={modules}
        formats={formats}
        style={{ height: '80%', color: 'black' }}
      />
    </div>
  </FormGroup>
</Col>

<Col md="6">
  <FormGroup>
    <label>Image:</label>
    <Input type="file" onChange={(e) => setNewAwardImage(e.target.files[0])} />
  </FormGroup>
</Col>


                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={handleAddService}
                    >
                      Add Awards
                    </Button>

                    {addSuccessMessage && (
                      <div
                        className="alert alert-success"
                        role="alert"
                        style={{
                          width: "50%",
                          margin: "10px auto",
                          textAlign: "center",
                        }}
                      >
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div style={{ color: "red", marginTop: "10px" }}>{addError}</div>
                    )}
                    {/* It seems ImageError is not used in this context, consider removing it if not needed */}
                  </Col>
                </Row>
              </Form>

              <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Title</th>
      <th>Paragraph</th>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {NewsSection.map((news, index) => (
      <tr key={index}>
        <td>{news.title}</td>
        <td dangerouslySetInnerHTML={{ __html: news.paragraph }}></td>
        
        <td>
          {news.imageUrl && (
            <img src={`https://back.zubilaw.com/${news.imageUrl}`} alt={`Awards ${index}`} style={{ width: '100px', height: '100px' }} />
          )}
        </td>
        <td>
          <Button color="success" onClick={() => handleUpdate1(news)}>
            Update
          </Button>
        </td>
        <td>
          <Button color="danger" onClick={() => handleDeleteImage(news.id)}>
            Delete
          </Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>


              {showUpdateImage && (
                <div className="content">
                  <Form onSubmit={handleEventUpdate}>
                  <Row>
                  <Col md="7">
  <FormGroup>
    <label>Title:</label>
    <Input
      type="text"
      value={updateImage.title}
      onChange={(e) => setUpdateImage({ ...updateImage, title: e.target.value })} // Corrected here
    />
  </FormGroup>
</Col>

  <Col md="7">
    <FormGroup>
      <label>Paragraph:</label>
      <div
        style={{
          height: '300px', // Adjust the height as needed
          overflowY: 'auto', // Add a vertical scrollbar when content overflows
        }}
      >
        <ReactQuill
          value={updateImage.paragraph}
          onChange={(value) =>
            setUpdateImage({ ...updateImage, paragraph: value })
          }
          modules={modules}
          formats={formats}
          style={{ height: '80%', color: 'black' }}
        />
      </div>
    </FormGroup>
  </Col>

  <Col md="6">
    <FormGroup>
      <label>Image:</label>
      <Input type="file" onChange={(e) => setUpdateAwardImage(e.target.files[0])} />
    </FormGroup>
  </Col>
</Row>


                    <Row>
                      <Col md="12">
                        <div className="update ml-auto mr-auto">
                          <Button className="btn-round" color="primary" type="submit">
                            Update awards
                          </Button>{" "}
                          <Button
                            className="btn-round"
                            color="secondary"
                            onClick={() => {
                              setShowUpdateImage(false);
                              setSelectedImageId(null);
                            }}
                          >
                            Cancel
                          </Button>
                          {updateErrorMessage && (
                            <div style={{ color: "red", marginTop: "10px" }}>
                              {updateErrorMessage}
                            </div>
                          )}

                          {updateError && (
                            <div style={{ color: "red", marginTop: "10px" }}>{updateError}</div>
                          )}
                          {updateSuccessMessage && (
                            <div
                              className="alert alert-success"
                              role="alert"
                              style={{
                                width: "50%",
                                margin: "10px auto",
                                textAlign: "center",
                              }}
                            >
                              {updateSuccessMessage}
                            </div>
                          )}
                          {UpdateRequired && (
                            <div style={{ color: "red", marginTop: "10px" }}>
                              {UpdateRequired}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={showDeleteNavbarAboutPopup}
        toggle={() => setShowDeleteNavbarAboutPopup(false)}
      >
        <ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this awards?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDeleteNavbarAbout}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>





      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Images Section</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Image:</label>
                      <Input type="file" onChange={(e) => setNewAwardImage(e.target.files[0])} />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button className="btn-round" color="primary" onClick={handleAddAwardImage}>
                      Add Image
                    </Button>
                    {addImageSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: "50%", margin: "10px auto", textAlign: "center" }}>
                        {addImageSuccessMessage}
                      </div>
                    )}
                    {addImageError && (
                      <div style={{ color: "red", marginTop: "10px" }}>{addImageError}</div>
                    )}
                  </Col>
                </Row>
              </Form>

              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Image</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {awardsDescriptionList.map((description, index) => (
                    <tr key={index}>
                      <td><img src={`https://back.zubilaw.com/${description.imageUrl}`} alt={`Awards ${description.id}`} style={{ width: '100px', height: '100px' }} /></td>
                      <td>
                        <Button color="success" onClick={() => { setSelectedAwardId(description.id); setShowUpdateAwardForm(true); }}>
                          Update
                        </Button>
                      </td>
                      <td>
                        <Button color="danger" onClick={() => handleDeleteAwardImage(description.id)}>
                          Delete
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {showUpdateAwardForm && (
                <div className="content">
                  <Form onSubmit={handleUpdateAwardImage}>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <label>Image:</label>
                          <Input type="file" onChange={(e) => setUpdateAwardImage(e.target.files[0])} />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="12">
                        <div className="update ml-auto mr-auto">
                          <Button className="btn-round" color="primary" type="submit">
                            Update Image
                          </Button>
                          <Button className="btn-round" color="secondary" onClick={() => { setShowUpdateAwardForm(false); setSelectedAwardId(null); }}>
                            Cancel
                          </Button>
                          {updateImageError && (
                            <div style={{ color: "red", marginTop: "10px" }}>{updateImageError}</div>
                          )}
                          {updateImageSuccessMessage && (
                            <div className="alert alert-success" role="alert" style={{ width: "50%", margin: "10px auto", textAlign: "center" }}>
                              {updateImageSuccessMessage}
                            </div>
                          )}
                        </div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal
        isOpen={showDeleteAwardModal}
        toggle={() => setShowDeleteAwardModal(false)}
      >
        <ModalHeader toggle={() => setShowDeleteAwardModal(false)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this image?</ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDeleteAwardImage}>
            Delete
          </Button>{" "}
          <Button color="secondary" onClick={() => setShowDeleteAwardModal(false)}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
 
    </div>
  );
}

export default Footer;
