import React, { useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardFooter,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap';
import axios from 'axios';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const SliderAwardsCMS = () => {
  const [imageAwards, setImageAwards] = useState([]);
  const [textAwards, setTextAwards] = useState([]);
  const [newImage, setNewImage] = useState(null);
  const [newText, setNewText] = useState('');
  const [addSuccessMessage, setAddSuccessMessage] = useState('');
  const [addError, setAddError] = useState('');
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState('');
  const [updateError, setUpdateError] = useState('');
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [awardToDelete, setAwardToDelete] = useState(null);
  const [editingImageAward, setEditingImageAward] = useState(null);
  const [editingTextAward, setEditingTextAward] = useState(null);
  const [imageErrorUpdate, setImageErrorUpdate] = useState('');

  useEffect(() => {
    fetchAwardsImages();
    fetchAwardsTexts();
  }, []);

  const fetchAwardsImages = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/SliderAward/listImages');
      setImageAwards(response.data.data);
    } catch (error) {
      console.error('Error fetching awards images:', error);
    }
  };

  const fetchAwardsTexts = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/SliderAward/listTexts');
      setTextAwards(response.data.data);
    } catch (error) {
      console.error('Error fetching awards texts:', error);
    }
  };

  const addAwardImage = async () => {
    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.post('https://back.zubilaw.com/SliderAward/addImage', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAddSuccessMessage('Award image added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewImage(null);
      fetchAwardsImages();
    } catch (error) {
      setAddError('Please select an image to upload.');
      console.error('Error adding award image:', error);
    }
  };

  const addAwardText = async () => {
    const data = {
      text: newText,
    };

    try {
      await axios.post('https://back.zubilaw.com/SliderAward/addText', data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setAddSuccessMessage('Award text added successfully!');
      setTimeout(() => {
        setAddSuccessMessage('');
      }, 5000);
      setNewText('');
      fetchAwardsTexts();
    } catch (error) {
      setAddError('Please enter text to add.');
      console.error('Error adding award text:', error);
    }
  };

  const updateAwardImage = async (id) => {
    const formData = new FormData();
    formData.append('slider', newImage);

    try {
      await axios.put(`https://back.zubilaw.com/SliderAward/updateImage/${id}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setUpdateSuccessMessage('Award image updated successfully!');
      setEditingImageAward(null);
      setNewImage(null);
      fetchAwardsImages();
    } catch (error) {
      setUpdateError('Error updating award image.');
      console.error('Error updating award image:', error);
    }
  };

  const updateAwardText = async (id) => {
    const data = {
      text: newText,
    };

    try {
      await axios.put(`https://back.zubilaw.com/SliderAward/updateText/${id}`, data, {
        headers: { 'Content-Type': 'application/json' },
      });
      setUpdateSuccessMessage('Award text updated successfully!');
      setEditingTextAward(null);
      setNewText('');
      fetchAwardsTexts();
    } catch (error) {
      setUpdateError('Error updating award text.');
      console.error('Error updating award text:', error);
    }
  };

  const deleteAward = (id) => {
    setAwardToDelete(id);
    setShowDeletePopup(true);
  };

  const performDelete = async () => {
    try {
      await axios.delete(`https://back.zubilaw.com/SliderAward/delete/${awardToDelete}`);
      setShowDeletePopup(false);
      fetchAwardsImages();
      fetchAwardsTexts();
    } catch (error) {
      console.error('Error deleting award:', error);
    }
  };

  const closeUpdateForm = () => {
    setEditingImageAward(null);
    setEditingTextAward(null);
    setNewImage(null);
    setNewText('');
  };

  const modules = {
    toolbar: [
      [{ 'color': [] }, { 'background': [] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'header': '1' }, { 'header': '2' }, { 'header': [3, 4, 5, 6] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['link', 'image', 'video'],
      ['clean']                                         
    ],
  };
  
  const formats = [
    'background', 'color', 'bold', 'italic', 'underline', 'strike', 'header',
    'list', 'bullet', 'align', 'link', 'image', 'video'
  ];

  return (
    <div className="content">
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Manage Awards Images</CardTitle>
            </CardHeader>
            <CardBody>
              <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Image</label>
                      <Input
                        type="file"
                        onChange={(e) => setNewImage(e.target.files[0])}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addAwardImage}
                    >
                      Add Award Image
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div style={{ color: "red", marginTop: "5px" }} role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form>
              <Table responsive>
  <thead className="text-primary">
    <tr>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {imageAwards.map((award, index) => (
      <tr key={award.id}>
        <td>
          <img
            src={`https://back.zubilaw.com/${award.slider}`}
            alt="Award Slider"
            style={{ maxWidth: '300px', maxHeight: '200px' }}
          />
        </td>
        <td>
          <Button
            color="success"
            onClick={() => {
              setEditingImageAward(award);
              setNewImage(null);
            }}
          >
            Update Image
          </Button>{" "}
          {index !== 0 && (
            <Button
              color="danger"
              onClick={() => deleteAward(award.id)}
            >
              Delete
            </Button>
          )}
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Text Details</CardTitle>
            </CardHeader>
            <CardBody>
              {/* <Form>
                <Row>
                  <Col md="6">
                    <FormGroup>
                      <label>Text</label>
                      <Input
                        type="text"
                        value={newText}
                        onChange={(e) => setNewText(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col md="12">
                    <Button
                      className="btn-round"
                      color="primary"
                      onClick={addAwardText}
                    >
                      Add Award Text
                    </Button>
                    {addSuccessMessage && (
                      <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                        {addSuccessMessage}
                      </div>
                    )}
                    {addError && (
                      <div style={{ color: "red", marginTop: "5px" }} role="alert">
                        {addError}
                      </div>
                    )}
                  </Col>
                </Row>
              </Form> */}
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Text</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {textAwards.map((award) => (
                    <tr key={award.id}>
                      <td dangerouslySetInnerHTML={{ __html: award.text }}></td>
                      <td>
                        <Button
                          color="success"
                          onClick={() => {
                            setEditingTextAward(award);
                            setNewText(award.text);
                          }}
                        >
                          Update Text
                        </Button>{" "}
                        {/* <Button
                          color="danger"
                          onClick={() => deleteAward(award.id)}
                        >
                          Delete
                        </Button> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </Col>
      </Row>

      {(editingImageAward || editingTextAward) && (
        <CardFooter>
          <Form>
            <Row>
              {editingTextAward && (
                <Col md="6">
                  <FormGroup>
                    <label>Text</label>
                    <div
                      style={{
                        height: '300px',
                        overflowY: 'auto',
                      }}
                    >
                      <ReactQuill
                        value={newText}
                        onChange={setNewText}
                        modules={modules} 
                        formats={formats}
                        style={{ height: '80%', color: 'black' }}
                      />
                    </div>
                  </FormGroup>
                </Col>
              )}

              {editingImageAward && (
                <Col md="6">
                  <FormGroup>
                    <label>Image</label>
                    <Input
                      type="file"
                      onChange={(e) => setNewImage(e.target.files[0])}
                    />
                  </FormGroup>
                </Col>
              )}
              <Col md="12">
                {editingTextAward && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateAwardText(editingTextAward.id)}
                  >
                    Update Text
                  </Button>
                )}{" "}
                {editingImageAward && (
                  <Button
                    className="btn-round"
                    color="primary"
                    onClick={() => updateAwardImage(editingImageAward.id)}
                  >
                    Update Image
                  </Button>
                )}{" "}
                <Button
                  className="btn-round"
                  color="secondary"
                  onClick={closeUpdateForm}
                >
                  Cancel
                </Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
                    {updateError}
                  </div>
                )}
                {imageErrorUpdate && (
                  <div style={{ color: 'red' }}>
                    {imageErrorUpdate}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
      )}

      <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
        <ModalHeader toggle={() => setShowDeletePopup(false)}>Delete Award</ModalHeader>
        <ModalBody>
          Are you sure you want to delete this award?
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={performDelete}>Delete</Button>
          <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SliderAwardsCMS;
