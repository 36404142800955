import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../assets/css/style.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button, Modal, ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

function NavbarComponent() {
 

  const [notifications, setNotifications] = useState([]);
  const [newNotification, setNewNotification] = useState({
    title: '',
    date: '', 
    time: '', 
    content: '',
  });
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateAboutForm, setShowUpdateAboutForm] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const [updateNotification, setUpdateNotification] = useState({
    title: '',
    date: '',
    time: '', 
    content: '',
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationCount, setnotificationCount] = useState(0);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [addErrorMessage, setAddErrorMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteNotificationId, setDeleteNotificationId] = useState(null);
  const [logoItems, setLogoItems] = useState([]);
  const [navbarItems, setNavbarItems] = useState([]);
  const [navbarAboutItems, setNavbarAboutItems] = useState([]);
  const [newNavbarItem, setNewNavbarItem] = useState({
    name: '',
    link: '',
    position: '',
    content: '',
  });
  const [newNavbaAboutItem, setNewNavbarAboutItem] = useState({
    name: '',
    link: '',
    position: '',
    content: '',
  });
  const [updateNavbarItem, setUpdateNavbarItem] = useState({
    id: null,
    name: '',
    link: '',
    position: '',
    content: '',
  });

  const [updateNavbarAboutItem, setUpdateNavbarAboutItem] = useState({
    id: null,
    name: '',
    link: '',
    position: '',
    content: '',
  });
  const [selectedNavbarItemId, setSelectedNavbarItemId] = useState(null);
  const [selectedNavbarAboutItemId, setSelectedNavbarAboutItemId] = useState(null);
  const [showDeleteNavbarPopup, setShowDeleteNavbarPopup] = useState(false);
  const [showDeleteNavbarAboutPopup, setShowDeleteNavbarAboutPopup] = useState(false);
  const [showUpdateLogo, setShowUpdateLogo] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentLogo, setCurrentLogo] = useState(null);
  const [updateError, setUpdateError] = useState("");


  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };




  const handleShowUpdateForm = (item) => {
    setCurrentLogo(item);
    setShowUpdateLogo(true);
    setUpdateSuccessMessage('');
    setUpdateErrorMessage('');
  };

  const handleUpdateSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) {
      setUpdateErrorMessage('Please select a valid image file before submitting.');
      return; // Exit the function if no valid file is selected
    }
  
    const formData = new FormData();
    formData.append('logo', selectedFile);
  
    try {
      const response = await axios.put(`https://back.zubilaw.com/logo/update/${currentLogo.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setUpdateSuccessMessage(response.data.message);
      setShowUpdateLogo(false); // Close the update form
      fetchLogo(); // Refresh the logos
    } catch (error) {
      console.error(`Error updating logo: ${error}`);
      setUpdateErrorMessage('Error updating logo. Please try again later.');
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && isValidImageExtension(file.name)) {
      setSelectedFile(file);
      setUpdateError(''); // Clear any previous error message immediately
    } else {
      setUpdateError('Invalid Logo file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG');
      // Clear the error message after 5 seconds
      setTimeout(() => {
        setUpdateError("");
      }, 5000);
      setSelectedFile(null); // Clear the selected file
    }
  };
  

  useEffect(() => {
    fetchLogo();
  }, []);

  const fetchLogo = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://back.zubilaw.com/logo/list');
      setLogoItems(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(`Error fetching logos: ${error}`);
      setError('Error fetching logos. Please try again later.');
      setLoading(false);
    }
  };


  



  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewNavbarItem({ ...newNavbarItem, [name]: value });
};

const handleSubmit = async (e) => {
  e.preventDefault();

  // Validate the input fields
  if (!newNavbarItem.name.trim() || !newNavbarItem.link.trim() || !newNavbarItem.position.trim() || !newNavbarItem.content.trim()) {
    setAddErrorMessage("Name, Link, and Position are required to add a navbar item.");
    setTimeout(() => {
      setAddErrorMessage("");
    }, 5000);
    return;
  }

  try {
    // Send a POST request to the add navbar item endpoint
    const response = await axios.post(
      'https://back.zubilaw.com/navbar/add', // Update this URL to match your API endpoint
      newNavbarItem
    );

   

if (response.status === 200 || response.status === 201) {
console.log('Navbar item added successfully:', response.data);
fetchData(); // Reload the navbar items to reflect the new addition
setNewNavbarItem({
name: '',
link: '',
position: '',
content: '',
});
setAddSuccessMessage("Navbar item added successfully");
setTimeout(() => {
setAddSuccessMessage("");
}, 5000);
}
} catch (error) {
console.error('Error adding navbar item:', error);
setAddErrorMessage("Failed to add navbar item. Please try again.");
setTimeout(() => {
setAddErrorMessage("");
}, 5000);
}
};


const fetchNavbarAboutItems = async () => {
  setLoading(true);
  try {
    const response = await axios.get('https://back.zubilaw.com/footer/list');
    setNavbarAboutItems(response.data.data);
  } catch (err) {
    console.error('Failed to fetch navbar items:', err);
    setError('Failed to load navbar items.');
  } finally {
    setLoading(false);
  }
};

useEffect(() => {
  fetchNavbarAboutItems();
}, []);

const handleInputAboutChange = (e) => {
  const { name, value } = e.target;
  setNewNavbarAboutItem({ ...newNavbaAboutItem, [name]: value });
};

const handleAboutSubmit = async (e) => {
  e.preventDefault();

  if (!newNavbaAboutItem.name.trim() || !newNavbaAboutItem.link.trim() || !newNavbaAboutItem.position.trim() || !newNavbaAboutItem.content.trim()) {
    setAddErrorMessage("Name, Link, Position and content are required to add a navbar item.");
    setTimeout(() => {
      setAddErrorMessage("");
    }, 5000);
    return;
  }

  try {
    const response = await axios.post('https://back.zubilaw.com/footer/add', newNavbaAboutItem);

    if (response.status === 200 || response.status === 201) {
      console.log('Navbar item added successfully:', response.data);
      fetchNavbarAboutItems();  // Call fetchNavbarAboutItems to reload items
      setNewNavbarAboutItem({ name: '', link: '', position: '', content: '' });
      setAddSuccessMessage("Navbar item added successfully");
      setTimeout(() => {
        setAddSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error adding navbar item:', error);
    setAddErrorMessage("Failed to add navbar item. Please try again.");
    setTimeout(() => {
      setAddErrorMessage("");
    }, 5000);
  }
};
  

  const fetchData = async () => {
    try {
      const response = await axios.get('https://back.zubilaw.com/navbar/list');
      setNavbarItems(response.data.data);
      setnotificationCount(response.data.data.length);
      setLoading(false);
      setError(null);
    } catch (error) {
      console.error(`Error getting notifications: ${error}`);
      setLoading(false);
      setError('Error fetching notifications. Please try again later.');
    }
  };

  const handleDeleteNavbar = (id) => {
    setSelectedNavbarItemId(id);
    setShowDeleteNavbarPopup(true);
  };
  
  const performDeleteNavbar = async () => { // Accept ID as a parameter
    try {
      const response = await axios.delete(`https://back.zubilaw.com/navbar/delete/${selectedNavbarItemId}`);
      if (response.status === 200) {
        const updatedNavbarItems = navbarItems.filter(item => item.id !== selectedNavbarItemId);
        setNavbarItems(updatedNavbarItems);
        setShowDeleteNavbarPopup(false);
      } else {
        console.error('Failed to delete navbar item');
      }
    } catch (error) {
      console.error('Error deleting navbar item:', error);
    }
  };
  





const handleDeleteNavbarAbout = (id) => {
  setSelectedNavbarAboutItemId(id);
  setShowDeleteNavbarAboutPopup(true);
};

const performDeleteNavbarAbout = async () => {
  try {
    const response = await axios.delete(`https://back.zubilaw.com/footer/delete/${selectedNavbarAboutItemId}`);
    if (response.status === 200) {
      const updatedNavbarAboutItems = navbarAboutItems.filter(item => item.id !== selectedNavbarAboutItemId);
      setNavbarAboutItems(updatedNavbarAboutItems);
      setShowDeleteNavbarAboutPopup(false);
    } else {
      console.error('Failed to delete navbar item');
    }
  } catch (error) {
    console.error('Error deleting navbar item:', error);
  }
};


// Replace fetchBackgrounds with fetchNotifications in useEffect and elsewhere as needed

  
const handleUpdate = (navbarItem) => {
  console.log("Updating navbar item:", navbarItem);
  if (navbarItem && 'position' in navbarItem) {
  setSelectedNavbarItemId(navbarItem.id);
  setUpdateNavbarItem({
    id: navbarItem.id,
    name: navbarItem.name,
    link: navbarItem.link,
    position: navbarItem.position.toString(),
    content: navbarItem.content
  });
  setShowUpdateForm(true);
} else {
  console.error("Navbar item does not have a position property");
  // Handle the error accordingly
}
};


const handleNavbarItemUpdate = async (e) => {
  e.preventDefault();

  // Check if all fields are filled
  if (!updateNavbarItem.name.trim() || !updateNavbarItem.link.trim() || !updateNavbarItem.position.trim() || !updateNavbarItem.content.trim()) {
    setUpdateErrorMessage("Name, Link, Position and content are required.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
    return;
  }

  try {
    const response = await axios.put(
      `https://back.zubilaw.com/navbar/update/${selectedNavbarItemId}`,
      updateNavbarItem
    );
    if (response.status === 200) {
      console.log('Navbar item updated successfully:', response.data);
      fetchData(); // Reload data
      setUpdateNavbarItem({ id: null, name: '', link: '', position: '', content: '' });
      setShowUpdateForm(false);
      setUpdateSuccessMessage("Navbar item updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating navbar item:', error);
    setUpdateErrorMessage("Failed to update navbar item. Please try again.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
  }
};

  
const handleUpdateAbout = (navbarItem) => {
  console.log("Updating navbar item:", navbarItem);
  if (navbarItem && 'position' in navbarItem) {
    setSelectedNavbarAboutItemId(navbarItem.id);
  setUpdateNavbarAboutItem({
    id: navbarItem.id,
    name: navbarItem.name,
    link: navbarItem.link,
    position: navbarItem.position.toString(),
    content: navbarItem.content,
  });
  setShowUpdateAboutForm(true);
} else {
  console.error("Navbar item does not have a position property");
  // Handle the error accordingly
}
};


const handleNavbarAboutItemUpdate = async (e) => {
  e.preventDefault();

  // Check if all fields are filled
  if (!updateNavbarAboutItem.name.trim() || !updateNavbarAboutItem.link.trim() || !updateNavbarAboutItem.position.trim() || !updateNavbarAboutItem.content.trim()) {
    setUpdateErrorMessage("Name, Link, Position and content are required.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
    return;
  }

  try {
    const response = await axios.put(
      `https://back.zubilaw.com/footer/update/${selectedNavbarAboutItemId}`,
      updateNavbarAboutItem
    );
    if (response.status === 200) {
      console.log('Navbar item updated successfully:', response.data);
      fetchNavbarAboutItems(); // Reload data
      setUpdateNavbarAboutItem({ id: null, name: '', link: '', position: '', content: '' });
      setShowUpdateAboutForm(false);
      setUpdateSuccessMessage("Navbar item updated successfully");
      setTimeout(() => {
        setUpdateSuccessMessage("");
      }, 5000);
    }
  } catch (error) {
    console.error('Error updating navbar item:', error);
    setUpdateErrorMessage("Failed to update navbar item. Please try again.");
    setTimeout(() => {
      setUpdateErrorMessage("");
    }, 5000);
  }
};
 




  

  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateForm]);
  
  useEffect(() => {
    if (showUpdateForm) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbar-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateForm]);



  
  useEffect(() => {
    fetchNavbarAboutItems();
    if (showUpdateAboutForm) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateAboutForm]);


  useEffect(() => {
    if (showUpdateAboutForm) {
      // Assuming you want to focus on the 'name' input field when editing
      const nameInputField = document.getElementById('edit-navbarAbout-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    }
  }, [showUpdateAboutForm]);
  
  

  return (
    <>

<div className="content">

        <CardHeader>
          <CardTitle tag="h4">Navbar Items List</CardTitle>
        </CardHeader>

        {loading ? (
          <p>Loading notifications...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
<Table>
  <thead>
    <tr>
      <th>name</th>
      <th>Content</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
    {navbarItems.slice(7).map((item) => ( // Use slice to skip the first 7 items
      <tr key={item.id}>
        <td>{item.name ? item.name : 'N/A'}</td>
        <td dangerouslySetInnerHTML={{ __html: item.content ? item.content : 'N/A' }}></td>
        <td>
          <Button color="success" onClick={() => handleUpdate(item)}>Update</Button>
          {' '}
          <Button color="danger" onClick={() => handleDeleteNavbar(item.id)}>Delete</Button>
        </td>
      </tr>
    ))}
  </tbody>
</Table>

            {showUpdateForm && (
    <Form onSubmit={handleNavbarItemUpdate} style={{ marginTop: '100px' }}>
      <Row>
        <Col md="7">
        <FormGroup>
  <label>Name:</label>
  <Input
    type="text"
    name="name"
    value={updateNavbarItem.name}
    id="edit-navbar-name" // Add this ID
    onChange={(e) =>
      setUpdateNavbarItem({
        ...updateNavbarItem,
        name: e.target.value,
      })
    }
  />
</FormGroup>

    </Col>
    <Col md="7">
  <FormGroup>
    <label>Page Content:</label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
    <ReactQuill
  theme="snow"
  value={updateNavbarItem.content || ''}
  onChange={(content) =>
    setUpdateNavbarItem({
      ...updateNavbarItem,
      content: content,
    })
  }
  modules={{
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['image'],
    ],
  }}
  style={{ height: '80%' }}
/>
</div>
  </FormGroup>
</Col>


  </Row>
  <Row>
    <Col md="12">
      <Button type="submit" color="primary">Update Navbar Item</Button>
      <Button color="secondary" onClick={() => setShowUpdateForm(false)}>Cancel</Button>
    </Col>
  </Row>
  {updateSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateSuccessMessage}
    </div>
  )}
  {updateErrorMessage && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateErrorMessage}
    </div>
  )}
</Form>

)}
          </>
        )}
        <Modal isOpen={showDeleteNavbarPopup} toggle={() => setShowDeleteNavbarPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this navbar item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbar}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal>











        {/* <CardHeader>
          <CardTitle tag="h4">Footer Items List</CardTitle>
        </CardHeader>
        <CardFooter>
          <div>Footer Items Total: {navbarAboutItems.length}</div>
        </CardFooter>
        {loading ? (
          <p>Loading notifications...</p>
        ) : error ? (
          <p>{error}</p>
        ) : (
          <>
            <Table>
              <thead>
                <tr>
                  <th>name</th>
                  <th>Content</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
              {navbarAboutItems.slice(4).map((item) => (
  <tr key={item.id}>
    <td>{item.name ? item.name : 'N/A'}</td>
    <td dangerouslySetInnerHTML={{ __html: item.content ? item.content : 'N/A' }}></td>
    <td>
    <Button color="success" onClick={() => handleUpdateAbout(item)}>Update</Button>
      {' '}
      <Button color="danger" onClick={() => handleDeleteNavbarAbout(item.id)}>Delete</Button>    </td>
  </tr>
))}
              </tbody>
            </Table>
            {showUpdateAboutForm && (
    <Form onSubmit={handleNavbarAboutItemUpdate} style={{ marginTop: '100px' }}>
      <Row>
        <Col md="7">
        <FormGroup>
  <label>Name:</label>
  <Input
    type="text"
    name="name"
    value={updateNavbarAboutItem.name}
    id="edit-navbarAbout-name" // Add this ID
    onChange={(e) =>
      setUpdateNavbarAboutItem({
        ...updateNavbarAboutItem,
        name: e.target.value,
      })
    }
  />
</FormGroup>

    </Col>

    <Col md="7">
  <FormGroup>
    <label>Page Content:</label>
    <div
      style={{
        height: '300px', // Adjust the height as needed
        overflowY: 'auto', // Add a vertical scrollbar when content overflows
      }}
    >
    <ReactQuill
  theme="snow"
  value={updateNavbarAboutItem.content || ''}
  onChange={(content) =>
    setUpdateNavbarAboutItem({
      ...updateNavbarAboutItem,
      content: content,
    })
  }
  modules={{
    toolbar: [
      [{ header: '1' }, { header: '2' }, { font: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['bold', 'italic', 'underline'],
      ['image'],
    ],
  }}
  style={{ height: '80%' }}
/>
</div>
  </FormGroup>
</Col>
 
  </Row>
  <Row>
    <Col md="12">
      <Button type="submit" color="primary">Update Footer Item</Button>
      <Button color="secondary" onClick={() => setShowUpdateAboutForm(false)}>Cancel</Button>
    </Col>
  </Row>
  {updateSuccessMessage && (
    <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateSuccessMessage}
    </div>
  )}
  {updateErrorMessage && (
    <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
      {updateErrorMessage}
    </div>
  )}
</Form>

)}
          </>
        )}
        <Modal isOpen={showDeleteNavbarAboutPopup} toggle={() => setShowDeleteNavbarAboutPopup(false)}>
<ModalHeader toggle={() => setShowDeleteNavbarAboutPopup(false)}>Confirm Delete</ModalHeader>
<ModalBody>
Are you sure you want to delete this navbar item?
</ModalBody>
<ModalFooter>
<Button color="danger" onClick={performDeleteNavbarAbout}>Delete</Button>{' '}
<Button color="secondary" onClick={() => setShowDeleteNavbarAboutPopup(false)}>Cancel</Button>
</ModalFooter>
</Modal> */}
</div>
</>
);
}

export default NavbarComponent;
