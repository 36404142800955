import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button ,Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";

function Icons() {
  const [icons, setIcons] = useState([]);
  const [title, setTitle] = useState([]);
  const [Lets, setLets] = useState([]);
  const [Together, setTogether] = useState([]);
  const [editingIcon, setEditingIcon] = useState(null);
  const [iconCount, setIconCount] = useState(0);
  const [newImage, setNewImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [addError, setAddError] = useState("");
  const [updateError, setUpdateError] = useState("");
  const [ImageError, setImageError] = useState("");
  const [ImageErrorUpdate, setImageErrorUpdate] = useState("");
  const [formInitialDisplay, setFormInitialDisplay] = useState(false);
  const [addSuccessMessage, setAddSuccessMessage] = useState("");
  const [updateSuccessMessage, setUpdateSuccessMessage] = useState("");
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [showUpdateFormTitle, setShowUpdateFormTitle] = useState(false);
  const [showUpdateFormLets, setShowUpdateFormLets] = useState(false);
  const [showUpdateFormTogether, setShowUpdateFormTogether] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deleteIconId, setDeleteIconId] = useState(null);
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");
  const [selectedTitleItemId, setSelectedTitleItemId] = useState(null);
  const [updateTitleItem, setUpdateTitleItem] = useState({
    id: null,
    name: ''
  });
 
  const [selectedLetsItemId, setSelectedLetsItemId] = useState(null);
  const [updateLetsItem, setUpdateLetsItem] = useState({
    id: null,
    name: ''
  });

  const [selectedTogetherItemId, setSelectedTogetherItemId] = useState(null);
  const [updateTogetherItem, setUpdateTogetherItem] = useState({
    id: null,
    name: ''
  });
  const [togetherItems, setTogetherItems] = useState([]); // Assuming this is your state for storing Together items



  

  const fetchIcons = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/slider/list");
      if (response.data.success) {
        setIcons(response.data.data);
        setIconCount(response.data.data.length);
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };




  const fetchTitle = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/infoslider/list");
      if (response.data.success) {
        setTitle(response.data.data);
        
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };


  const fetchTogether = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/together/list");
      if (response.data.success) {
        setTogetherItems(response.data.data);
        
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };




  useEffect(() => {
    fetchTitle();
    if (showUpdateFormTitle) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateFormTitle]);




  const fetchLets = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/letswork/list");
      if (response.data.success) {
        setLets(response.data.data);
        
      } else {
        console.error("API request was not successful:", response.data.errors);
      }
    } catch (error) {
      console.error("Error fetching icons:", error);
    }
  };




  useEffect(() => {
    fetchLets();
    if (showUpdateFormLets) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateFormLets]);



  useEffect(() => {
    fetchTogether();
    if (showUpdateFormTogether) {
      // Focus on the input field when the form is shown
      const inputField = document.getElementById('update-notification-title');
      if (inputField) {
        inputField.focus();
      }
    }
  }, [showUpdateFormTogether]);






  const isValidImageExtension = (fileName) => {
    const validExtensions = ['.webp', '.gif', '.png', '.jpg', '.jpeg'];
    const fileExtension = fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2).toLowerCase();
    return validExtensions.includes(`.${fileExtension}`);
  };
  const addImage = async () => {
 
    const imageInput = document.getElementById("newIconimage");
  

    const imageFile = imageInput.files[0];
  
    if ( !imageFile) {
      setAddError("The image is required.");
      setTimeout(() => { setAddError(""); }, 5000);
      return;
    }
  
    if (!isValidImageExtension(imageFile.name)) {
      setImageError("Invalid file extension. Please upload .WEBP, .GIF, .PNG, .JPG, or .JPEG");
      setTimeout(() => { setImageError(""); }, 5000);
      return;
    }
  
    const formData = new FormData();

    formData.append("slider", imageFile);
  
    try {
      const response = await axios.post("https://back.zubilaw.com/slider/add", formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
  
      if (response.data.success) {
        fetchIcons();
        setAddSuccessMessage("Social Media added successfully");
        setTimeout(() => { setAddSuccessMessage(""); }, 5000);
  
      
        imageInput.value = "";
  
      } else {
        setAddError("Failed to add icon: " + response.data.errors);
      }
    } catch (error) {
      console.error("Error adding icon:", error);
      setAddError("Error occurred whileadding the icon.");
      setTimeout(() => {
        setAddError("");
      }, 5000);
  }
  };







  
  
  const handleUpdate = (icon) => {
    setEditingIcon(icon);
    setShowUpdateForm(true); // Show the update form
  };
  const closeUpdateForm = () => {
    setEditingIcon(null);
    setShowUpdateForm(false);
  };
  

  const updateIcon = async (id) => {
    // Validation checks

  
    // Creating a FormData object to handle the data being sent in the PUT request
    const formData = new FormData();

  
    // Adding the new image to the formData only if it exists and is valid
    if (newImage) {
      if (!isValidImageExtension(newImage.name)) {
        setImageErrorUpdate("Invalid file extension. Allowed: .WEBP, .GIF, .PNG, .JPG, .JPEG");
        setTimeout(() => {
          setImageErrorUpdate("");
        }, 5000);
        return;
      }
      formData.append("slider", newImage);
    }
  
    // Making an asynchronous PUT request to update the icon
    try {
      const response = await axios.put(`https://back.zubilaw.com/slider/update/${id}`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Handling the response after the icon is successfully updated
      if (response.data.success) {
        fetchIcons(); // Refresh the list of icons
        setUpdateSuccessMessage("Social Media updated successfully");
        setTimeout(() => {
          setUpdateSuccessMessage("");
        }, 5000);
  
        // Reset the update form fields but keep the update section visible
        setEditingIcon({ ...editingIcon, slider: "" });
        setNewImage(null);
      } else {
        setUpdateError("Failed to update icon: " + response.data.errors);
      }
    } catch (error) {
      setUpdateError("Error occurred while updating the icon.");
    }
  };
  



  const handleUpdateTitle = (TitleItem) => {
    console.log("Updating Title item:", TitleItem);
    setSelectedTitleItemId(TitleItem.id);
    setUpdateTitleItem({ id: TitleItem.id, name: TitleItem.name });
    setShowUpdateFormTitle(true);
  };

  // Function to handle the submission of the updated title item
  const handleTitleItemUpdate = async (e) => {
    e.preventDefault();
    if (!updateTitleItem.name.trim()) {
      setUpdateError("Name is required.");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }

    try {
      const response = await axios.put(`https://back.zubilaw.com/infoslider/update/${selectedTitleItemId}`, updateTitleItem);
      if (response.data.success) {
        console.log('Title item updated successfully:', response.data);
        // Assuming you have a function to fetch and refresh title items list
        fetchTitle(); // Reload data
        setUpdateTitleItem({ id: null, name: '' });
        setShowUpdateFormTitle(false);
        setUpdateSuccessMessage("Title item updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
      }
    } catch (error) {
      console.error('Error updating Title item:', error);
      setUpdateError("Failed to update Title item. Please try again.");
      setTimeout(() => setUpdateError(""), 5000);
    }
  };




  const handleUpdateLets = (LetsItem) => {
    console.log("Updating Lets item:", LetsItem);
    setSelectedLetsItemId(LetsItem.id);
    setUpdateLetsItem({ id: LetsItem.id, name: LetsItem.name });
    setShowUpdateFormLets(true);
  };

  // Function to handle the submission of the updated Lets item
  const handleLetsItemUpdate = async (e) => {
    e.preventDefault();
    if (!updateLetsItem.name.trim()) {
      setUpdateError("Name is required.");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }

    try {
      const response = await axios.put(`https://back.zubilaw.com/letswork/update/${selectedLetsItemId}`, updateLetsItem);
      if (response.data.success) {
        console.log('Lets item updated successfully:', response.data);
        // Assuming you have a function to fetch and refresh Lets items list
        fetchLets(); // Reload data
        setUpdateLetsItem({ id: null, name: '' });
        setShowUpdateFormLets(false);
        setUpdateSuccessMessage("Lets item updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
      }
    } catch (error) {
      console.error('Error updating Lets item:', error);
      setUpdateError("Failed to update Lets item. Please try again.");
      setTimeout(() => setUpdateError(""), 5000);
    }
  };




  const handleUpdateTogether = (togetherItem) => {
    console.log("Updating Together item:", togetherItem);
    setSelectedTogetherItemId(togetherItem.id);
    setUpdateTogetherItem({ id: togetherItem.id, name: togetherItem.name });
    setShowUpdateFormTogether(true);
  };

  const handleTogetherItemUpdate = async (e) => {
    e.preventDefault();
    if (!updateTogetherItem.name.trim()) {
      setUpdateError("Name is required.");
      setTimeout(() => setUpdateError(""), 5000);
      return;
    }

    try {
      const response = await axios.put(`https://back.zubilaw.com/together/update/${selectedTogetherItemId}`, updateTogetherItem);
      if (response.data.success) {
        console.log('Together item updated successfully:', response.data);
        // fetchTogether(); // Reload data
        setUpdateTogetherItem({ id: null, name: '' });
        setShowUpdateFormTogether(false);
        setUpdateSuccessMessage("Together item updated successfully");
        setTimeout(() => setUpdateSuccessMessage(""), 5000);
      }
    } catch (error) {
      console.error('Error updating Together item:', error);
      setUpdateError("Failed to update Together item. Please try again.");
      setTimeout(() => setUpdateError(""), 5000);
    }
  };

  

  
const deleteIcon = (id) => {
  setDeleteIconId(id);
  setShowDeletePopup(true);
};



const performDelete = async () => {
  try {
    const response = await axios.delete(`https://back.zubilaw.com/slider/delete/${deleteIconId}`);
    if (response.data.success) {
      setIcons(icons.filter((icon) => icon.id !== deleteIconId));
      setShowDeletePopup(false);
    } else {
      console.error("Failed to delete icon");
    }
  } catch (error) {
    console.error("Error deleting icon:", error);
  }
};


  useEffect(() => {
    fetchIcons();
  }, []);

  useEffect(() => {
    if (editingIcon && !formInitialDisplay) {
      setFormInitialDisplay(true); // Indicate that the form is being displayed initially
      const nameInputField = document.getElementById('edit-icon-name');
      if (nameInputField) {
        nameInputField.focus();
      }
    } else if (!editingIcon && formInitialDisplay) {
      setFormInitialDisplay(false); // Reset when the form is closed
    }
  }, [editingIcon]);

  return (
    <>
      <div className="content">
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Slider Image</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                  <Row>

                    <Col md="8">
                      <FormGroup>
                        <label>Image:</label>
                        <Input
                          type="file"
                          name="image"
                          id="newIconimage"
                          onChange={(e) => {
                            setNewImage(e.target.files[0]);
                            
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button
                        className="btn-round"
                        color="primary"
                        onClick={addImage}
                      >
                        Add Image
                      </Button>
                      {addSuccessMessage && (
                        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                          {addSuccessMessage}
                        </div>
                      )}
                                       {addError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {addError}
  </div>
)}
{ImageError && (
  <div style={{ color: 'red' }}>
    {ImageError}
  </div>
)}


                    </Col>
                  </Row>
                </Form>
              

                <CardFooter>
                  <div>Total Image: {iconCount}</div>
                </CardFooter>
                
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Image</th>
                    
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {icons.map((icon) => (
                      <tr key={icon.id}>
                  
                        <td>
                          {icon.slider && (
                            <img
                              src={`https://back.zubilaw.com/${icon.slider}`}
                              style={{ maxWidth: "300px", maxHeight: "200px" }}
                            />
                          )}
                        </td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => handleUpdate(icon)}
                          >
                            Update
                          </Button>{" "}
                          <Button
                            color="danger"
                            onClick={() => deleteIcon(icon.id)}
                          >
                            Delete
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showUpdateForm && editingIcon && (
                <CardFooter>
                  <Form>
                    <Row>
                      
                      <Col md="8">
          <FormGroup>
            <label>Current Image:</label>
            {editingIcon.slider && (
              <div>
                <img
                  src={`https://back.zubilaw.com/uploads/${editingIcon.slider}`}
                  alt="Icon"
                  style={{ maxWidth: '200px', marginBottom: '10px' }}
                />
              </div>
            )}
            <label>Update Image (optional):</label>
            <Input
              type="file"
              name="image"
              onChange={(e) => setNewImage(e.target.files[0])}
            />
          </FormGroup>
        </Col>
                      <Col md="12">
                        <Button
                          className="btn-round"
                          color="info"
                          onClick={() => updateIcon(editingIcon.id)}
                        >
                          Update Image
                        </Button>{" "}
                        <Button
      className="btn-round"
      color="secondary"
      onClick={closeUpdateForm}
    >
      Cancel
    </Button>
                         {updateSuccessMessage && (
                            <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
                              {updateSuccessMessage}
                            </div>
                          )}
{updateError && (
  <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
    {updateError}
  </div>
)}
{ImageErrorUpdate && (
  <div style={{ color: 'red' }}>
    {ImageErrorUpdate}
  </div>
)}


                      </Col>
                    </Row>
                  </Form>
                </CardFooter>
              )}
            </Card>
          </Col>
        </Row>
        <Modal isOpen={showDeletePopup} toggle={() => setShowDeletePopup(false)}>
  <ModalHeader toggle={() => setShowDeletePopup(false)}>Confirm Delete</ModalHeader>
  <ModalBody>
    Are you sure you want to delete this icon?
  </ModalBody>
  <ModalFooter>
    <Button color="danger" onClick={performDelete}>Delete</Button>
    <Button color="secondary" onClick={() => setShowDeletePopup(false)}>Cancel</Button>
  </ModalFooter>
</Modal>

      






     
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Slider Title</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                 
                </Form>
              

 
                
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Title</th>
                    
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {title.map((item) => (
                      <tr key={item.id}>
                  
                  <td>{item.name}</td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => handleUpdateTitle(item)}
                          >
                            Update
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showUpdateFormTitle && (
        <CardFooter>
          <Form onSubmit={handleTitleItemUpdate}>
            <Row>
              <Col md="8">
                <FormGroup>
                  <label>Update Title:</label>
                  <Input
                    type="text"
                    value={updateTitleItem.name}
                    onChange={(e) =>
                      setUpdateTitleItem({
                        ...updateTitleItem,
                        name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Button type="submit" color="primary">Update Title Item</Button>{' '}
                <Button color="secondary" onClick={() => setShowUpdateFormTitle(false)}>Cancel</Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert">
                    {updateError}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
              )}
            </Card>
          </Col>
        </Row>


     




      
        <Row>
          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">Slider Let's Work</CardTitle>
              </CardHeader>
              <CardBody>
                <Form>
                 
                </Form>
              

 
                
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Title</th>
                    
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Lets.map((item) => (
                      <tr key={item.id}>
                  
                  <td>{item.name}</td>
                        <td>
                          <Button
                            color="success"
                            onClick={() => handleUpdateLets(item)}
                          >
                            Update
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
              {showUpdateFormLets && (
        <CardFooter>
          <Form onSubmit={handleLetsItemUpdate}>
            <Row>
              <Col md="8">
                <FormGroup>
                  <label>Update Lets:</label>
                  <Input
                    type="text"
                    value={updateLetsItem.name}
                    onChange={(e) =>
                      setUpdateLetsItem({
                        ...updateLetsItem,
                        name: e.target.value,
                      })
                    }
                  />
                </FormGroup>
              </Col>
              <Col md="12">
                <Button type="submit" color="primary">Update Lets Item</Button>{' '}
                <Button color="secondary" onClick={() => setShowUpdateFormLets(false)}>Cancel</Button>
                {updateSuccessMessage && (
                  <div className="alert alert-success" role="alert">
                    {updateSuccessMessage}
                  </div>
                )}
                {updateError && (
                  <div className="alert alert-danger" role="alert">
                    {updateError}
                  </div>
                )}
              </Col>
            </Row>
          </Form>
        </CardFooter>
              )}
            </Card>
          </Col>
        </Row>


     




      
      <Row>
        <Col md="12">
          <Card>
            <CardHeader>
              <CardTitle tag="h4">Slider Together</CardTitle>
            </CardHeader>
            <CardBody>
              <Table responsive>
                <thead className="text-primary">
                  <tr>
                    <th>Title</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {togetherItems.map((item) => (
                    <tr key={item.id}>
                      <td>{item.name}</td>
                      <td>
                        <Button color="success" onClick={() => handleUpdateTogether(item)}>Update</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            {showUpdateFormTogether && (
              <CardFooter>
                <Form onSubmit={handleTogetherItemUpdate}>
                  <Row>
                    <Col md="8">
                      <FormGroup>
                        <label>Update Together:</label>
                        <Input type="text" value={updateTogetherItem.name} onChange={(e) => setUpdateTogetherItem({ ...updateTogetherItem, name: e.target.value })} />
                      </FormGroup>
                    </Col>
                    <Col md="12">
                      <Button type="submit" color="primary">Update Together Item</Button>{' '}
                      <Button color="secondary" onClick={() => setShowUpdateFormTogether(false)}>Cancel</Button>
                      {updateSuccessMessage && <div className="alert alert-success" role="alert">{updateSuccessMessage}</div>}
                      {updateError && <div className="alert alert-danger" role="alert">{updateError}</div>}
                    </Col>
                  </Row>
                </Form>
              </CardFooter>
            )}
          </Card>
        </Col>
      </Row>
    </div>





      
    </>
  );
}

export default Icons;
