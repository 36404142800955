// Terms.js (Front-End)

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  Table,
  Form,
  FormGroup,
  Input,
  Button,
} from "reactstrap";

function Footer() {
  const [terms, setTerms] = useState([]);

  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [selectedTermId, setSelectedTermId] = useState(null);
  const [updateTerm, setUpdateTerm] = useState({
    title: "",
    description: "",
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [termCount, setTermCount] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [updateErrorMessage, setUpdateErrorMessage] = useState("");











  

  const fetchData = async () => {
    try {
      const response = await axios.get("https://back.zubilaw.com/about/list");
      if (response.data && response.data.success) {
        setTerms(response.data.data); // Wrapping the object in an array
        setTermCount(1); // Since it'sa single object
      } else {
        console.error("Unexpected response format:", response.data);
        // Handle this situation appropriately
      }
    } catch (error) {
      console.error(`Error getting data: ${error}`);
      setLoading(false);
      setError("Error fetching data. Please try again later.");
    }
  };
  
  



  const handleUpdate = (term) => {
    setSelectedTermId(term.id);
    setUpdateTerm({
      title: term.title,
      description: term.description,
    });
    setShowUpdateForm(!showUpdateForm);
    };

    const handleTermUpdate = async (e) => {
      e.preventDefault();
    
      // Validation: Check if title and description are filled
      if (!updateTerm.title.trim() || !updateTerm.description.trim()) {
        setUpdateErrorMessage("Title and description are required.");
        setTimeout(() => {
          setUpdateErrorMessage("");
        }, 5000);
    
        return;
      }
    
      try {
        const response = await axios.put(
          `https://back.zubilaw.com/about/update/${selectedTermId}`,
          updateTerm
        );
    
        if (response.status === 200) {
          console.log("Term updated successfully:", response.data);
          fetchData();
          setUpdateTerm({ title: "", description: "" });
          setSelectedTermId(null);
          setSuccessMessage("Term updated successfully");
          setUpdateErrorMessage(""); // Clear any existing error message
          setTimeout(() => {
            setSuccessMessage("");
          }, 5000);
        }
      } catch (error) {
        console.error("Error updating term:", error);
        setUpdateErrorMessage("Failed to update term. Please try again.");
      }
    };
    


  useEffect(() => {
    fetchData();
    if (showUpdateForm) {
      // Assuming the first input field has an id 'update-title'
      document.getElementById('update-title').focus();
    }
  }, [showUpdateForm]);











  return (
    <div className="content">
  <div >

  
        
          <Row>
            <Col md="12">
              <Card>
                <CardHeader>
                  <CardTitle tag="h4">About Section</CardTitle>
                </CardHeader>
                <CardBody>
                  <Table responsive>
                    <thead className="text-primary">
                      <tr>
                        <th>Title</th>
                        <th>description</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {terms.map((term) => (
                        <tr key={term.id}>
                          <td>{term.title}</td>
                          <td>{term.description}</td>
                          <td>
                            
                            <Button 
                             color="success"
                            onClick={() => handleUpdate(term)}>
                              Update
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      

      {showUpdateForm  && (
    <div className="content mt-5">
                <Form onSubmit={handleTermUpdate}>
            <Row>
              <Col md="6">
                <FormGroup>
                  <label>Title:</label>
                  <Input
                    type="text"
                    name="title"
                    id="update-title"
                    value={updateTerm.title}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, title: e.target.value })
                    }
                  />
                </FormGroup>
              </Col>
            
              <Col md="9">
                <FormGroup>
                <label for="content" className="textarea-label">description:</label>
               <textarea
                //  style={{ width: '500px' }}
                    type="text"
                    name="description"
                    value={updateTerm.description}
                    onChange={(e) =>
                      setUpdateTerm({ ...updateTerm, description: e.target.value })
                    }
                    style={{ minHeight: '300px', width: '590px'  }}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md="12">
                <div className="update ml-auto mr-auto">
                  <Button className="btn-round" color="primary" type="submit">
                    Update Term
                  </Button>{" "}
      <Button
        className="btn-round"
        color="secondary"
        onClick={() => {
          setShowUpdateForm(false);
          setSelectedTermId(null);
          setUpdateTerm({
            title: "",
            description: "",
          });
        }}
      >
        Cancel
      </Button>
      {successMessage && (
        <div className="alert alert-success" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center'  }}>
          {successMessage}
        </div>
      )}

{updateErrorMessage && (
        <div className="alert alert-danger" role="alert" style={{ width: '50%', margin: '10px auto', textAlign: 'center' }}>
          {updateErrorMessage}
        </div>
      )}

                </div>
              </Col>
            </Row>
          </Form>
        </div>
      )}






























    </div>
  );
}

export default Footer;
